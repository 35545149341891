import { Box, Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProfessionalInfoCard from "../../../components/Cards/ProfessionalInfoCard";
import BottomTabs from "../../../components/UI/BottomTabs";
import DashboardHeader from "../../../components/UI/DashboardHeader";
import { COLORS } from "../../../styles/Theme";
import CircularProgress from "@mui/material/CircularProgress";
import { addToWishlistApi, getWishlistApi } from "../../../services/api_calls/auth/AuthApi";

function Wishlist() {
  const navigate = useNavigate();
  const [wishlistItems, setWishlistItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchWishlistData = async () => {
    try {
      setIsLoading(true);
      const response = await getWishlistApi();
      
      // Check if the response indicates unauthorized access or failed authentication
      if (!response || !response.status) {
        // Redirect to OTP verification page
        navigate("/otpVerify");
        return;
      }

      const formattedData = (response.data || []).map(item => ({
        ...item,
        profileImg: item.profileImg || item.profileImages?.[0] || "/assets/images/home-banner.png",
        businessName: item.businessName || item.bname || "N/A",
        rating: item.rating || item.Rating || 0,
        reviewCount: item.reviewCount || item.totalReviews || 0,
        address: formatAddress(item.address?.[0]),
        timings: formatTimings(item.timings),
        services: Array.isArray(item.categories) 
          ? item.categories.map(category => typeof category === 'object' ? category.name : category)
          : [],
        isVerified: item.status === 4,
        phoneNumber: item.phoneNumber || item.bmobile || "N/A",
        isSubscribed: !!item.subscription
      }));
      setWishlistItems(formattedData);
    } catch (err) {
      // If there's an error fetching wishlist, redirect to OTP verification
      console.error("Error fetching wishlist:", err);
      navigate("/otpVerify");
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to format address
  const formatAddress = (addressObj) => {
    if (!addressObj) return "N/A";
    const { baddress, barea, bcity, bstate, pincode } = addressObj;
    return [baddress, barea, bcity, bstate, pincode]
      .filter(Boolean)
      .join(", ") || "N/A";
  };

  // Helper function to format timings
  const formatTimings = (timingsObj) => {
    if (!timingsObj?.default?.[1]) return "N/A";
    const { start, end } = timingsObj.default[1];
    return start && end ? `${start} - ${end}` : "N/A";
  };

  useEffect(() => {
    // Check for stored mobile number before fetching wishlist
    const storedMobileNumber = localStorage.getItem('userMobileNumber');
    if (!storedMobileNumber) {
      navigate("/otpVerify");
      return;
    }

    fetchWishlistData();
  }, [navigate]);

  const handleClearAll = async () => {
    try {
      for (const item of wishlistItems) {
        await addToWishlistApi({
          vendor_id: item.vendor_id,
          service_id: item.service_id,
        });
      }
      await fetchWishlistData();
    } catch (err) {
      console.error("Error clearing wishlist:", err);
      navigate("/otpVerify");
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const handleWishlistUpdate = async () => {
    // Refresh the wishlist data
    await fetchWishlistData();
  };

  return (
    <Box>
      <DashboardHeader />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        m="15px"
      >
        <Typography variant="h3" fontSize={16} fontWeight={500}>
          {wishlistItems.length} Items in Wishlist
        </Typography>

        {wishlistItems.length > 0 && (
          <Button
            sx={{ color: COLORS.pinkishRed, textTransform: "none" }}
            onClick={handleClearAll}
          >
            Clear All
          </Button>
        )}
      </Box>

      <Box m="0 15px">
        {wishlistItems.map((item, index) => (
          <Box key={`wishlist_item_${item.vendor_id}_${index}`} mb={2}>
            <ProfessionalInfoCard
              imgUrl={item.profileImg}
              name={item.businessName}
              title={item.businessName}
              rating={item.rating}
              reviewCount={item.reviewCount}
              timings={item.timings}
              address={item.address}
              services={item.services}
              isVerified={item.status === 4}
              vendor_id={item.vendor_id}
              service_id={item.service_id}
              isWishlisted={true}
              addToWishlistApi={addToWishlistApi}
              getWishlistApi={getWishlistApi}
              onContantClick={() => {}}
              phoneNumber={item.phoneNumber}
              businessName={item.businessName}
              isSubscribed={item.isSubscribed}
              distance={item.distance || ""}
              onWishlistUpdate={handleWishlistUpdate} 
            />
          </Box>
        ))}

        {wishlistItems.length === 0 && (
          <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            height="50vh"
          >
            <Typography variant="body1" color="textSecondary">
              No items in wishlist
            </Typography>
          </Box>
        )}
      </Box>

      <BottomTabs activeTab="favorites" />
    </Box>
  );
}

export default Wishlist;