import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  IconButton,
  styled,
  createTheme,
  ThemeProvider,
  useMediaQuery,
  Breadcrumbs,
  Link,
  Chip,
  Skeleton,
  Alert,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BottomTabs from "../../components/UI/BottomTabs";
import Header from "../../components/UI/DashboardHeader/Header";
import Footer from "../../components/UI/Footer/Footer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  getBlogPosts,
  incrementReadCount,
} from "../../services/api_calls/auth/AuthApi";

const PageContainer = styled(Container)(({ theme }) => ({
  paddingTop: 20,
  paddingBottom: 40,

  [theme.breakpoints.up("sm")]: {
    paddingTop: 110,
  },

  [theme.breakpoints.down("sm")]: {
    "& h1": {
      fontSize: "2rem !important",
    },
    "& h2": {
      fontSize: "1.5rem !important",
    },
    "& h3": {
      fontSize: "1.25rem !important",
    },
  },
}));

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  "& .MuiBreadcrumbs-separator": {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const ArticleImage = styled("img")({
  width: "100%",
  height: "auto",
  maxHeight: "500px",
  objectFit: "cover",
  borderRadius: "12px",
  marginBottom: "2rem",
});

const MetaInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  flexWrap: "wrap",

  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const MetaItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  color: theme.palette.text.secondary,

  [theme.breakpoints.down("sm")]: {
    "& .MuiSvgIcon-root": {
      fontSize: "0.9rem",
    },
    "& .MuiTypography-root": {
      fontSize: "0.8rem",
    },
  },
}));

const SocialShare = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  marginTop: theme.spacing(4),
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  "& h1": {
    fontSize: "2rem",
    fontWeight: 600,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  "& h2": {
    fontSize: "1.75rem",
    fontWeight: 600,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  "& h3": {
    fontSize: "1.5rem",
    fontWeight: 600,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  "& p": {
    fontSize: "1.1rem",
    lineHeight: 1.7,
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  "& ul, & ol": {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  "& li": {
    marginBottom: theme.spacing(1),
    fontSize: "1.1rem",
    color: theme.palette.text.secondary,
  },

  [theme.breakpoints.down("sm")]: {
    "& h1": { fontSize: "1.5rem" },
    "& h2": { fontSize: "1.35rem" },
    "& h3": { fontSize: "1.2rem" },
    "& p, & li": { fontSize: "1rem" },
  },
}));

const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          maxWidth: "1200px",
        },
      },
    },
  },
});

const formatDate = (dateString) => {
  if (!dateString || isNaN(new Date(dateString).getTime())) {
    return "Date unavailable";
  }

  try {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Date unavailable";
  }
};

const calculateReadTime = (content) => {
  if (!content) return 1;
  const wordsPerMinute = 200;
  const words = content.trim().split(/\s+/).length;
  const readTime = Math.ceil(words / wordsPerMinute);
  return readTime || 1;
};

const BlogDetail = () => {
  const { blogName } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [readCount, setReadCount] = useState(0);
  const location = useLocation();

  const fetchBlogPost = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getBlogPosts();

      if (response.success && response.posts) {
        const postId = location.state?.postId;
        const foundPost = postId
          ? response.posts.find((post) => post._id === postId)
          : response.posts.find((post) => {
              const urlFriendlyTitle = post.title
                .toLowerCase()
                .replace(/[^a-z0-9]+/g, "-")
                .replace(/(^-|-$)/g, "");
              return urlFriendlyTitle === blogName;
            });

        if (foundPost) {
          setPost(foundPost);
          setReadCount(foundPost.readCount || 0);
          // Increment read count when post is found
          const countResponse = await incrementReadCount(foundPost._id);
          if (countResponse.success) {
            setReadCount(countResponse.readCount);
          }
        } else {
          setError("Blog post not found.");
        }
      } else {
        setError("Failed to load blog post.");
      }
    } catch (error) {
      console.error("Error fetching blog post:", error);
      setError("Failed to load blog post. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [blogName, location.state]);

  useEffect(() => {
    fetchBlogPost();
  }, [fetchBlogPost]);

  const handleShare = (platform) => {
    const url = window.location.href;
    const title = post?.title || "";

    switch (platform) {
      case "whatsapp":
        window.open(
          `https://wa.me/?text=${encodeURIComponent(title + " " + url)}`
        );
        break;
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            title
          )}&url=${encodeURIComponent(url)}`
        );
        break;
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            url
          )}`
        );
        break;
      default:
        break;
    }
  };

  const renderContent = (content) => {
    if (!content) return null;

    return content.split("\n").map((line, index) => {
      const cleanLine = line.replace(/`/g, "").trim();
      if (!cleanLine) return <Box key={index} sx={{ height: "1rem" }} />;

      if (cleanLine.startsWith("# ")) {
        return (
          <Typography variant="h1" key={index}>
            {cleanLine.slice(2)}
          </Typography>
        );
      }
      if (cleanLine.startsWith("## ")) {
        return (
          <Typography variant="h2" key={index}>
            {cleanLine.slice(3)}
          </Typography>
        );
      }
      if (cleanLine.startsWith("### ")) {
        return (
          <Typography variant="h3" key={index}>
            {cleanLine.slice(4)}
          </Typography>
        );
      }

      return (
        <Typography paragraph key={index}>
          {cleanLine}
        </Typography>
      );
    });
  };

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Header />
        <PageContainer maxWidth="lg">
          <Alert severity="error" sx={{ mt: 4 }}>
            {error}
          </Alert>
        </PageContainer>
        <Footer />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <PageContainer maxWidth="lg">
        {loading ? (
          <Box>
            <Skeleton variant="text" width="60%" height={60} />
            <Skeleton
              variant="rectangular"
              height={400}
              sx={{ mt: 2, borderRadius: 2 }}
            />
            <Skeleton variant="text" width="80%" sx={{ mt: 2 }} />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </Box>
        ) : (
          <>
            <StyledBreadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link
                color="inherit"
                href="/blog"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/blog");
                }}
              >
                Blog
              </Link>
              {post?.category && (
                <Link
                  color="inherit"
                  href={`/blog/category/${post.category.name}`}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/blog/category/${post.category.name}`, {
                      state: { categoryId: post.category._id },
                    });
                  }}
                >
                  {post.category.name}
                </Link>
              )}
              <Typography color="text.primary">{post?.title}</Typography>
            </StyledBreadcrumbs>

            <Typography
              variant="h3"
              component="h1"
              gutterBottom
              sx={{
                fontSize: { xs: "1.75rem", sm: "2.5rem", md: "3rem" },
                fontWeight: 600,
              }}
            >
              {post?.title}
            </Typography>

            <MetaInfo>
              <MetaItem>
                <CalendarTodayIcon fontSize="small" />
                <Typography variant="body2">
                  {formatDate(post?.createdAt)}
                </Typography>
              </MetaItem>
              <MetaItem>
                <AccessTimeIcon fontSize="small" />
                <Typography variant="body2">
                  {calculateReadTime(post?.content)} min read
                </Typography>
              </MetaItem>
              <MetaItem>
                <VisibilityIcon fontSize="small" />
                <Typography variant="body2">
                  {readCount} {readCount === 1 ? "read" : "reads"}
                </Typography>
              </MetaItem>
              {post?.category && (
                <Chip
                  label={post.category.name}
                  color="primary"
                  size="small"
                  sx={{
                    fontSize: { xs: "0.7rem", sm: "0.8rem" },
                    height: { xs: 20, sm: 24 },
                  }}
                />
              )}
            </MetaInfo>

            {post?.image && (
              <Box sx={{ width: "100%", position: "relative", mb: 3 }}>
                <ArticleImage
                  src={post.image}
                  alt={post.title}
                  onError={(e) => {
                    console.log("Image load error:", e);
                    e.target.onerror = null; // Prevent infinite loop
                    e.target.src = "/assets/images/home-banner.png";
                  }}
                  loading="lazy"
                  sx={{
                    maxHeight: "500px",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "12px",
                  }}
                />
              </Box>
            )}

            <ContentWrapper>{renderContent(post?.content)}</ContentWrapper>

            <SocialShare>
              <IconButton
                onClick={() => handleShare("whatsapp")}
                color="primary"
              >
                <WhatsAppIcon />
              </IconButton>
              <IconButton
                onClick={() => handleShare("twitter")}
                color="primary"
              >
                <TwitterIcon />
              </IconButton>
              <IconButton
                onClick={() => handleShare("facebook")}
                color="primary"
              >
                <FacebookIcon />
              </IconButton>
            </SocialShare>
          </>
        )}
      </PageContainer>
      {isMobile && <BottomTabs activeTab="blog" />}
      <Footer />
    </ThemeProvider>
  );
};

export default BlogDetail;
