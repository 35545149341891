// import React from 'react';
// import { Box, Grid, Typography } from '@mui/material';
// import {
//   SectionTitle,
//   StyledButton,
//   PhoneAndAppsContainer,
//   PhoneImageContainer,
//   AppImageContainerStyled,
//   RightSectionContainer
  
// } from './Home.style'; 
// import ContactDetailsPopup from './ContactDetailsModal';
// import OTPPopup from './OtpModal';
// import SuccessPopup from './SuccessModal';


// const RightSection = ({
//   showContactPopup,
//   setShowContactPopup,
//   handleContactSubmit,
//   showOTPPopup,
//   setShowOTPPopup,
//   otp,
//   setOtp,
//   handleOtpSubmit,
//   showSuccessPopup,
//   setShowSuccessPopup
// }) => {
//   const handleListBusinessClick = () => {
//     setShowContactPopup(true);
//   };

//   return (
//     <Grid item xs={12} md={6}>
//     <RightSectionContainer>
//       <SectionTitle>
//         List your Business with Local Pro
//       </SectionTitle>

//       <Typography
//   variant="body1"
//   color="#7B7B7B"
//   mb={3}
//   sx={{
//     // fontFamily: 'normal normal normal 25px Poppins',
//     font:'normal normal normal 20px Poppins',
//     lineHeight: '1.5', 
//   }}
// >
//   Download "Local Pro" App <br />
//   And grow your business in <br />3 easy steps
// </Typography>


//       <StyledButton variant="contained" onClick={handleListBusinessClick}sx={{font:'normal normal normal 15px Poppins',}}>
//   List your Business for Free
// </StyledButton>
// <ContactDetailsPopup 
//   open={showContactPopup} 
//   handleClose={() => setShowContactPopup(false)} 
//   handleSubmit={handleContactSubmit} 
// />

// <OTPPopup 
//   open={showOTPPopup} 
//   handleClose={() => setShowOTPPopup(false)} 
//   otp={otp} 
//   setOtp={setOtp}
//   handleOtpSubmit={handleOtpSubmit}
// />

// <SuccessPopup 
//   open={showSuccessPopup} 
//   handleClose={() => setShowSuccessPopup(false)} 
// />
// <PhoneAndAppsContainer>
// <PhoneImageContainer>
//   <img
//     src="assets/images/phone.png"
//     alt="Phone"
//     style={{ maxWidth: '280px', height: 'auto' }}
//   />
// </PhoneImageContainer>
// <AppImageContainerStyled>
//   <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
//     <img src="assets/images/Image 13.png" alt="Google Play Store" style={{ width: '200px', height: 'auto' }} />
//   </a>
//   <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
//     <img src="assets/images/Image 14.png" alt="Apple App Store" style={{ width: '200px', height: 'auto' }} />
//   </a>
// </AppImageContainerStyled>
// </PhoneAndAppsContainer>
//     </RightSectionContainer>
//   </Grid>
//   );
// };

// export default RightSection;
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  SectionTitle,
  StyledButton,
  PhoneAndAppsContainer,
  PhoneImageContainer,
  AppImageContainerStyled,
  RightSectionContainer
} from './Home.style';

const RightSection = () => {
  const navigate = useNavigate();

  const handleListBusinessClick = () => {
    navigate('/download');
  };

  return (
    <Grid item xs={12} md={6}>
      <RightSectionContainer>
        <SectionTitle>
          List your Business with Local Pro
        </SectionTitle>

        <Typography
          variant="body1"
          color="#7B7B7B"
          mb={3}
          sx={{
            font: 'normal normal normal 20px Poppins',
            lineHeight: '1.5', 
          }}
        >
          Download "Local Pro" App <br />
          And grow your business in <br />3 easy steps
        </Typography>

        <StyledButton 
          variant="contained" 
          onClick={handleListBusinessClick}
          sx={{ font: 'normal normal normal 15px Poppins' }}
        >
          List your Business for Free
        </StyledButton>

        <PhoneAndAppsContainer>
          <PhoneImageContainer>
            <img
              src="/assets/images/phone.png"
              alt="Phone"
              style={{ maxWidth: '280px', height: 'auto' }}
            />
          </PhoneImageContainer>
          <AppImageContainerStyled>
            <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
              <img src="/assets/images/Image 13.png" alt="Google Play Store" style={{ width: '200px', height: 'auto' }} />
            </a>
            <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
              <img src="/assets/images/Image 14.png" alt="Apple App Store" style={{ width: '200px', height: 'auto' }} />
            </a>
          </AppImageContainerStyled>
        </PhoneAndAppsContainer>
      </RightSectionContainer>
    </Grid>
  );
};

export default RightSection;
