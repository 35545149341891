import React from 'react';
import { 
  Box, 
  Typography, 
  Button 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const MobileListBusinessBanner = () => {
  const navigate = useNavigate();

  const handleListBusinessClick = () => {
    navigate('/download');
  };

  return (
    <Box
      sx={{
        backgroundColor: "#EAF6FF",
        padding: "10px",
        marginTop: "20px",
        border: "1px solid #448EC6",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{ 
          color: "#21608F", 
          fontSize: "15px", 
          fontWeight: "bold" 
        }}
      >
        List your Business with Local Pro
      </Typography>

      <div>
        <Button
          variant="contained"
          color="primary"
          sx={{ 
            padding: "5px 10px", 
            backgroundColor: "#21608F" 
          }}
          onClick={handleListBusinessClick}
        >
          List Now
        </Button>
      </div>
    </Box>
  );
};

export default MobileListBusinessBanner;