import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, IconButton, Button } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getCityDataApi } from "../../../services/api_calls/auth/AuthApi";

const GOOGLE_MAPS_API_KEY = "AIzaSyDXXczVbYGv1aHHQ_o4bL4ptBoj1-0VjDA";

const LocationSelection = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [detectedLocation, setDetectedLocation] =
    useState("Detect my location");
  const [cities, setCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [recentSearches, setRecentSearches] = useState(() => {
    const stored = localStorage.getItem("recentLocations");
    return stored ? JSON.parse(stored) : [];
  });

  // Fetch cities
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await getCityDataApi();
        if (response?.cities) {
          setCities(response.cities);
          setFilteredCities(response.cities);
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
  }, []);

  // Handle search input
  useEffect(() => {
    if (searchTerm) {
      const filtered = cities.filter((city) =>
        city.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCities(filtered);
    } else {
      setFilteredCities(cities);
    }
  }, [searchTerm, cities]);

  const handleLocationDetection = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`
            );
            if (response.data.results.length > 0) {
              const result = response.data.results[0];
              const fullAddress = result.formatted_address;
              
              // Get the area/locality
              const addressComponents = result.address_components;
              const area = addressComponents.find(
                (component) =>
                  component.types.includes("sublocality") ||
                  component.types.includes("locality")
              );
              
              if (area) {
                const locationName = area.long_name;
                setDetectedLocation(locationName);
                // Store both full address and short location
                localStorage.setItem('fullLocation', fullAddress);
                localStorage.setItem('selectedCity', locationName);
                navigate("/", { 
                  state: { 
                    selectedLocation: fullAddress,
                    shortLocation: locationName 
                  } 
                });
              }
            }
          } catch (error) {
            console.error("Error fetching location:", error);
            setDetectedLocation("Unable to detect location");
          }
        },
        (error) => {
          console.error("Geolocation error:", error);
          setDetectedLocation("Unable to detect location");
        }
      );
    } else {
      setDetectedLocation("Geolocation is not supported");
    }
  };

  const handleSelectLocation = (location) => {
    updateRecentSearches(location);
    navigate("/", { state: { selectedLocation: location } });
  };

  const updateRecentSearches = (location) => {
    const updated = [
      location,
      ...recentSearches.filter((item) => item !== location),
    ].slice(0, 5);
    setRecentSearches(updated);
    localStorage.setItem("recentLocations", JSON.stringify(updated));
  };

  const handleClearRecent = (locationToRemove) => {
    const updated = recentSearches.filter(
      (location) => location !== locationToRemove
    );
    setRecentSearches(updated);
    localStorage.setItem("recentLocations", JSON.stringify(updated));
  };

  const handleClearAll = () => {
    setRecentSearches([]);
    localStorage.removeItem("recentLocations");
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 350,
        padding: 2,
        borderRadius: 1,
        boxShadow: 3,
        overflowY: "auto",
        maxHeight: "90vh",
        bgcolor: "background.paper",
      }}
    >
      <Typography variant="h6" gutterBottom fontWeight={500}>
        Your Location
      </Typography>

      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search for your city..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          endAdornment: <SearchIcon color="action" />,
        }}
        sx={{ mb: 2 }}
      />

      <Box sx={{ mb: 3, cursor: "pointer" }}>
        <Typography
          variant="subtitle1"
          onClick={handleLocationDetection}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "primary.main",
          }}
        >
          <LocationOnIcon />
          {detectedLocation}
        </Typography>
      </Box>

      {recentSearches.length > 0 && (
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" fontWeight={500} gutterBottom>
            Recent Searches
          </Typography>
          {recentSearches.map((location, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ py: 0.5 }}
            >
              <Typography
                onClick={() => handleSelectLocation(location)}
                sx={{ cursor: "pointer" }}
              >
                {location}
              </Typography>
              <IconButton
                size="small"
                onClick={() => handleClearRecent(location)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}

      <Box>
        <Typography variant="subtitle1" fontWeight={500} gutterBottom>
          All Cities
        </Typography>
        <Box sx={{ maxHeight: 300, overflowY: "auto" }}>
          {filteredCities.map((city, index) => (
            <Typography
              key={city.id || index}
              onClick={() => handleSelectLocation(city.name)}
              sx={{
                cursor: "pointer",
                py: 0.5,
                "&:hover": {
                  color: "primary.main",
                },
              }}
            >
              {city.name}
            </Typography>
          ))}
        </Box>
      </Box>

      {recentSearches.length > 0 && (
        <Button
          variant="outlined"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleClearAll}
        >
          Clear All Recent Searches
        </Button>
      )}
    </Box>
  );
};

export default LocationSelection;

// import React, { useState } from 'react';
// import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemText } from '@mui/material';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import ClearIcon from '@mui/icons-material/Clear';
// import SearchIcon from '@mui/icons-material/Search';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

// const GOOGLE_MAPS_API_KEY = 'AIzaSyDWkNTFYErrmPTv7VwC22Zl7gY14uU4k7g';

// const LocationSelection = () => {
//   const navigate = useNavigate();
//   const [searchTerm, setSearchTerm] = useState('');
//   const [citySuggestions, setCitySuggestions] = useState([]);
//   const [detectedLocation, setDetectedLocation] = useState('Detect my location');

//   const fetchCitySuggestions = async (input) => {
//     try {
//       const response = await axios.get(
//         `https://maps.googleapis.com/maps/api/place/autocomplete/json`,
//         {
//           params: {
//             input,
//             types: '(cities)',
//             key: GOOGLE_MAPS_API_KEY,
//           },
//         }
//       );
//       if (response.data.status === 'OK') {
//         setCitySuggestions(response.data.predictions);
//       } else {
//         console.error('Error fetching suggestions:', response.data.status);
//         setCitySuggestions([]);
//       }
//     } catch (error) {
//       console.error('Error fetching city suggestions:', error);
//       setCitySuggestions([]);
//     }
//   };

//   const handleSearchChange = (event) => {
//     const value = event.target.value;
//     setSearchTerm(value);
//     if (value.length > 2) {
//       fetchCitySuggestions(value);
//     } else {
//       setCitySuggestions([]);
//     }
//   };

//   const handleSelectLocation = (location) => {
//     navigate('/', { state: { selectedLocation: location } });
//   };

//   const handleLocationDetection = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         async (position) => {
//           const { latitude, longitude } = position.coords;
//           try {
//             const response = await axios.get(
//               `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`
//             );
//             if (response.data.results.length > 0) {
//               const addressComponents = response.data.results[0].address_components;
//               const city = addressComponents.find((component) =>
//                 component.types.includes('locality')
//               );
//               if (city) {
//                 setDetectedLocation(city.long_name);
//                 navigate('/', { state: { selectedLocation: city.long_name } });
//               } else {
//                 setDetectedLocation('Location not found');
//               }
//             } else {
//               setDetectedLocation('Location not found');
//             }
//           } catch (error) {
//             console.error('Error fetching location:', error);
//             setDetectedLocation('Unable to retrieve location');
//           }
//         },
//         (error) => {
//           console.error('Geolocation error:', error);
//           setDetectedLocation('Unable to retrieve location');
//         }
//       );
//     } else {
//       setDetectedLocation('Geolocation is not supported by this browser.');
//     }
//   };

//   return (
//     <Box sx={{ width: '100%', maxWidth: 350, padding: 2, borderRadius: 1, boxShadow: 3 }}>
//       <Typography variant="h6" gutterBottom>
//         Your Location
//       </Typography>
//       <TextField
//         fullWidth
//         variant="outlined"
//         placeholder="Start typing your location..."
//         value={searchTerm}
//         onChange={handleSearchChange}
//         InputProps={{
//           endAdornment: (
//             <IconButton>
//               <SearchIcon />
//             </IconButton>
//           ),
//         }}
//       />
//       <List>
//         {citySuggestions.map((suggestion, index) => (
//           <ListItem button key={index} onClick={() => handleSelectLocation(suggestion.description)}>
//             <ListItemText primary={suggestion.description} />
//           </ListItem>
//         ))}
//       </List>
//       <Typography sx={{ my: 2 }} />
//       <Box>
//         <Typography variant="subtitle1" onClick={handleLocationDetection} sx={{ cursor: 'pointer' }}>
//           {detectedLocation}
//         </Typography>
//       </Box>
//       <Button variant="outlined" fullWidth sx={{ mt: 2 }}>
//         Clear All
//       </Button>
//     </Box>
//   );
// };

// export default LocationSelection;
