import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Box,
  createTheme,
  ThemeProvider,
  useMediaQuery,
  Breadcrumbs,
  Link,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import BlogCard from './Blogcard';
import Header from '../../components/UI/DashboardHeader/Header';
import Footer from '../../components/UI/Footer/Footer';
import Loader from '../../components/Loader/Loader';
import BottomTabs from '../../components/UI/BottomTabs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { getBlogPosts } from '../../services/api_calls/auth/AuthApi';

const PageContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(5),

  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(14),
  },
}));

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '& .MuiBreadcrumbs-separator': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2),
  },
}));

const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          maxWidth: "1200px",
        },
      },
    },
  },
});

const CategoryPosts = () => {
  const { categoryName } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const categoryId = location.state?.categoryId;
  const isMobile = useMediaQuery("(max-width:600px)");
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCategoryPosts();
  }, [categoryId]);

  const fetchCategoryPosts = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await getBlogPosts(categoryId);

      const categoryPosts = response.posts.filter(post => 
        post.category?._id === categoryId || post.category === categoryId
      );
      
      setPosts(categoryPosts);
    } catch (error) {
      console.error('Error fetching category posts:', error);
      setError('Failed to load posts. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Header />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
          <Loader />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <PageContainer maxWidth="lg">
        <StyledBreadcrumbs 
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link 
            color="inherit" 
            onClick={(e) => {
              e.preventDefault();
              navigate('/blog');
            }}
            sx={{ cursor: 'pointer' }}
          >
            Blog
          </Link>
          <Typography color="text.primary">{categoryName}</Typography>
        </StyledBreadcrumbs>

        <Typography 
          variant="h3" 
          component="h1" 
          gutterBottom 
          sx={{ 
            mb: { xs: 2, sm: 3, md: 4 },
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }
          }}
        >
          {categoryName} Articles
        </Typography>

        {error ? (
          <Alert severity="error" sx={{ mb: 4 }}>{error}</Alert>
        ) : (
          <>
            <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
              {posts.map((post) => (
                <Grid item xs={12} sm={6} md={4} key={post._id}>
                  <BlogCard post={post} />
                </Grid>
              ))}
            </Grid>

            {posts.length === 0 && (
              <Box sx={{ textAlign: 'center', py: { xs: 4, sm: 6, md: 8 } }}>
                <Typography 
                  variant="h6" 
                  color="text.secondary"
                  sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
                >
                  No posts found in this category.
                </Typography>
              </Box>
            )}
          </>
        )}
      </PageContainer>
      {isMobile && <BottomTabs activeTab="blog" />}
      <Footer />
    </ThemeProvider>
  );
};

export default CategoryPosts;