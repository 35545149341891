import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/UI/DashboardHeader/DownloadHeader";
import Footer from "../../../components/UI/Footer/DownloadFooter";
import { fetchCategories } from "../../../redux/category/categorySlice";
import { useLocation } from "react-router-dom";
import "./landing.css";
const LocalProLanding = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    categories,
    loading: categoriesLoading,
    error: categoriesError,
  } = useSelector(
    (state) =>
      state.categories || {
        categories: [],
        loading: false,
        error: null,
      }
  );
  const scrollContainerRef = useRef(null);
  useEffect(() => {
    // Handle navigation based on URL hash
    if (location.hash) {
      const sectionId = location.hash.slice(1); // Remove the # symbol
      const sectionElement = document.getElementById(sectionId);

      if (sectionElement) {
        // Add a small delay to ensure DOM is fully loaded
        setTimeout(() => {
          sectionElement.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    } else {
      // If no hash, scroll to top when component mounts
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);
  useEffect(() => {
    if (!scrollContainerRef.current || categories.length === 0) return;

    const container = scrollContainerRef.current;
    const maxScroll = container.scrollWidth - container.clientWidth;
    let animationFrameId = null;
    let currentPosition = 0;
    let isPaused = false;
    let pauseTimeout = null;

    // Force scroll to start at the beginning
    container.scrollLeft = 0;

    const SCROLL_SPEED = 2; // Speed as requested

    const animate = () => {
      if (!isPaused) {
        currentPosition += SCROLL_SPEED;

        // If we've reached the end, pause briefly then reset
        if (currentPosition >= maxScroll) {
          isPaused = true;
          pauseTimeout = setTimeout(() => {
            currentPosition = 0;
            container.scrollLeft = 0;
            isPaused = false;
            animationFrameId = requestAnimationFrame(animate);
          }, 1500); // Pause for 1.5 seconds at the end
          return;
        }

        // Apply the scroll position
        container.scrollLeft = currentPosition;
      }

      animationFrameId = requestAnimationFrame(animate);
    };

    // Small delay to ensure DOM is ready
    const startTimeout = setTimeout(() => {
      animationFrameId = requestAnimationFrame(animate);
    }, 100);

    // Cleanup function
    return () => {
      cancelAnimationFrame(animationFrameId);
      if (pauseTimeout) clearTimeout(pauseTimeout);
      if (startTimeout) clearTimeout(startTimeout);
    };
  }, [categories]);

  // Add this function to your LocalProLanding component

  // First, add this useEffect to set up the animations
  useEffect(() => {
    // Create a style element for our animations
    const styleEl = document.createElement("style");
    styleEl.innerHTML = `
    /* Smooth scrolling for the whole page */
    html {
      scroll-behavior: smooth;
      scroll-padding-top: 70px;
    }

    /* Animation for section highlight when navigated to */
    @keyframes highlight-pulse {
      0% { box-shadow: 0 0 0 rgba(255, 138, 0, 0); }
      50% { box-shadow: 0 0 20px rgba(255, 138, 0, 0.3); }
      100% { box-shadow: 0 0 0 rgba(255, 138, 0, 0); }
    }

    .active-section {
      animation: highlight-pulse 1.5s ease-in-out;
    }

    /* Initial state and animation for sections */
    .section-animation {
      opacity: 0;
      transform: translateY(20px);
      transition: opacity 0.8s ease, transform 0.8s ease;
    }

    .visible {
      opacity: 1;
      transform: translateY(0);
    }
  `;
    document.head.appendChild(styleEl);

    // Set up intersection observer for fade-in animations
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    // Add classes and observe all sections
    const sections = document.querySelectorAll(
      "#home, #popular-services, #about-us, #contact-us"
    );
    sections.forEach((section) => {
      section.classList.add("section-animation");
      observer.observe(section);
    });

    // Clean up on component unmount
    return () => {
      document.head.removeChild(styleEl);
      observer.disconnect();
    };
  }, []);
  return (
    <div>
      <Header />
      <div id="home" className="container">
        {/* Gradient Overlay */}
        <div className="gradientOverlay"></div>

        {/* Main Content */}
        <div className="contentWrapper">
          {/* Mobile Mockup */}
          <div className="imageSection">
            <img
              src="/./assets/images/Group 5560.png"
              alt="Local Pro App"
              className="mobileMockup"
            />
          </div>

          {/* Content Section */}
          <div className="textSection">
            <h2 className="title">Grow Your Business with Local Pro!!!</h2>
            <p className="subtitle">Business Listing in 3 Simple Steps</p>
            <ul className="stepsList">
              <li>• Enter your mobile number login account</li>
              <li>• Enter your business details</li>
              <li>• Upload business logo and photo</li>
            </ul>

            {/* App Store Buttons */}
            {/* App Store Buttons with Desktop and Mobile Versions */}
            <div className="storeButtons">
              {/* Google Play Store Buttons */}
              <a
                href="https://play.google.com/store"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* Desktop Google Play Button */}
                <img
                  src="/./assets/images/Image 13.png"
                  alt="Google Play"
                  className="storeIcon storeIcon-desktop"
                />
                {/* Mobile Google Play Button */}
                <img
                  src="/./assets/images/googlepay.png"
                  alt="Google Play"
                  className="storeIcon storeIcon-mobile"
                />
              </a>

              {/* Apple App Store Buttons */}
              <a
                href="https://www.apple.com/app-store/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* Desktop App Store Button */}
                <img
                  src="/./assets/images/Image 14.png"
                  alt="App Store"
                  className="storeIcon storeIcon-desktop"
                />
                {/* Mobile App Store Button */}
                <img
                  src="/./assets/images/appstore.png"
                  alt="App Store"
                  className="storeIcon storeIcon-mobile"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <div className="howItWorksContainer">
        <h2 className="howItWorksTitle">How it works?</h2>
        <p className="howItWorksSubtitle">
          Explore some of the best tips from around the world from our Partner
          and friends.
        </p>

        <div className="stepsContainer">
          {howItWorksData.map((item, index) => (
            <div key={index} className="stepCard">
              <img src={item.icon} alt={item.title} className="stepIcon" />
              <h3 className="stepTitle">{item.title}</h3>
              <p className="stepDescription">{item.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Popular Services Section */}
      <div id="popular-services" className="popularServicesContainer">
        <h2 className="sectionTitle">Popular services in Local Pro</h2>
        <p className="subTitle">
          {categories.length > 0
            ? `${categories.reduce(
                (total, category) =>
                  total + (category.subCategory?.length || 0),
                0
              )}+ Services`
            : "300+ Services"}
        </p>

        {categoriesLoading ? (
          <div className="loadingMessage">Loading services...</div>
        ) : categoriesError ? (
          <div className="errorMessage">{categoriesError}</div>
        ) : categories && categories.length > 0 ? (
          <div ref={scrollContainerRef} className="serviceCardsContainer">
            {/* Map through all categories to ensure every card is rendered */}
            {categories.map((category, index) => (
              <div key={category.id || index} className="serviceCard">
                <div className="imageWrapper">
                  <img
                    src={
                      category.image ||
                      (category.banner && category.banner.length > 0
                        ? category.banner[0].url
                        : "")
                    }
                    alt={category.name}
                    className="serviceImage"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/./assets/images/placeholder.png";
                    }}
                  />
                  <h3 className="serviceTitle">{category.name}</h3>
                </div>
                <div className="serviceInfo">
                  <p className="serviceDetails">
                    {(category.subCategory && category.subCategory.length) || 0}{" "}
                    Categories | {Math.floor(Math.random() * 50) + 50} Service
                    Providers
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="loadingMessage">No services available</div>
        )}
      </div>

      {/* Business Statistics Section */}
      <div className="statsContainer">
        {statsData.map((stat, index) => (
          <div key={index} className="statCard">
            <h2 className="statNumber">{stat.value}</h2>
            <p className="statLabel">{stat.label}</p>
          </div>
        ))}
      </div>

      {/* About Local Pro Section */}
      <div id="about-us" className="aboutContainer">
        <div className="aboutContent">
          {/* Left Side - Text */}
          <div className="aboutText">
            <h2 className="aboutTitle">About Local Pro</h2>
            <p className="aboutDescription">
              At Local Pro, our mission is to bridge the gap between quality
              local service providers and community members seeking reliable
              solutions. We strive to create a platform where trust,
              professionalism, and excellence come together to serve our
              communities better.
            </p>
          </div>
          {/* Right Side - Illustration */}
          <div className="aboutImage">
            <img
              src="/./assets/images/connecting.png"
              alt="Connecting Around"
              className="image"
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

const howItWorksData = [
    {
      icon: "/./assets/images/user.png",
      title: "Create an account",
      description:
        "Sign up for a Local Pro account in seconds. Provide your basic information and verify your mobile number to get started with our platform.",
    },
    {
      icon: "/./assets/images/my-business.png",
      title: "Add your business",
      description:
        "Fill in your business details including location, services offered, business hours, and contact information to create your professional profile.",
    },
    {
      icon: "/./assets/images/handshake.png",
      title: "Connect nearby",
      description:
        "Get discovered by potential customers in your locality. Our smart algorithm helps match local customers with your services based on proximity and needs.",
    },
    {
      icon: "/./assets/images/target.png",
      title: "Achieve goals",
      description:
        "Grow your customer base and increase revenue. Track performance with our built-in analytics and expand your business with our promotional tools.",
    },
  ];
const statsData = [
  { value: "20 Lakh+", label: "Monthly Visitors" },
  { value: "6 Lakh+", label: "Enquiries" },
  { value: "25000+", label: "Business Registrations" },
  { value: "10 Lakh+", label: "Happy Clients" },
];

export default LocalProLanding;
