// import styled from "@emotion/styled";
// import { Box, Typography } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
// import OTPInput from "../../../components/UI/Input/OTPInput";
// import PhoneNumberInput from "../../../components/UI/Input/PhoneNumberInput";
// import {
//   userSentOtp,
//   userVerifyOtp,
// } from "../../../services/api_calls/auth/AuthApi";
// import { COLORS } from "../../../styles/Theme";
// import { useDispatch, useSelector } from "react-redux";
// import { clearError, verifyOTP } from "../../../redux/auth/AuthSlice";


// function OTPVerify() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { loading, error, isLoggedIn, userData } = useSelector((state) => state.auth);
//   const [mobileNumber, setMobileNumber] = useState("");
//   const [otp, setOtp] = useState("");

//   const [seconds, setSeconds] = useState(0);
//   const [startTimer, setStartTimer] = useState(false);

//   const [numberDisabled, setNumberDisabled] = useState(false);
//   const [otpDisabled, setOtpDisabled] = useState(true);

//   useEffect(() => {
//     if (error) {
//       // Handle error display
//       setTimeout(() => {
//         dispatch(clearError());
//       }, 3000);
//     }
//   }, [error, dispatch]);
//   // const handleSendOTP = () => {
//   //   setOtpDisabled(false);
//   //   setNumberDisabled(true)
//   // };

//   const handleSendOTP = async (event) => {
//     event.preventDefault();
//     if (mobileNumber) {
//       const response = await userSentOtp({ mobileNumber });
//       if (response.status) {
//         setOtpDisabled(false);
//         setNumberDisabled(true);
//         setSeconds(30);
//         setStartTimer(true);
//       }
//     }
//   };

//   const handleReSendOTP = async (event) => {
//     const response = await userSentOtp({ mobileNumber });

//     setSeconds(30);
//     setStartTimer(true);

//     // if (otp && mobileNumber) {
//     //   const response = await userVerifyOtp({ otp, mobileNumber });
//     //   console.log(response.access_token);
//     // }
//   };

//   const handleEditNumber = () => {
//     setNumberDisabled(false);
//     setOtpDisabled(true);
//   };

//   const handleLogin = async (event) => {
//     event.preventDefault();
//     if (otp && mobileNumber) {
//       try {
//         const resultAction = await dispatch(verifyOTP({ 
//           mobilenumber: mobileNumber,
//           otp: otp 
//         })).unwrap();
        
//         if (resultAction.user && resultAction.user.is_registered) {
//           navigate("/home");
//         } else {
//           navigate("/registerDetails");
//         }
//       } catch (err) {
//         console.error("Failed to verify OTP:", err);
//       }
//     }
//   };
//   const handleSkip = () => {
//     navigate("/home");
//   };

//   useEffect(() => {
//     let intervalId;

//     if (startTimer) {
//       intervalId = setInterval(() => {
//         if (seconds > 0) {
//           setSeconds(seconds - 1);
//         } else {
//           clearInterval(intervalId);
//           setStartTimer(false);
//         }
//       }, 1000);
//     }

//     return () => clearInterval(intervalId);
//   }, [startTimer, seconds]);

//   return (
//     <Box>
//       <Box display="flex" flexDirection="column" alignItems="center">
//         <StyledImg component="img" src="/./assets/images/auth-img.png" />

//         <Typography
//           variant="h2"
//           fontSize={18}
//           fontWeight={600}
//           color={COLORS.blackShade1}
//           textAlign="center"
//         >
//           WELCOME TO LOCAL PRO
//         </Typography>

//         <Typography
//           variant="body1"
//           fontSize={13}
//           fontWeight={500}
//           color={COLORS.grey}
//           textAlign="center"
//         >
//           Enter OTP To Continue
//         </Typography>
//       </Box>

//       <FormStyled>
//         <PhoneNumberInput
//           placeholder="Enter Mobile Number"
//           value={mobileNumber}
//           onChange={({ target }) => setMobileNumber(target.value)}
//           handleClick={handleSendOTP}
//           handleEditClick={handleEditNumber}
//           disabled={numberDisabled}
//           btnDisabled={mobileNumber.length !== 10}
//         />

//         <OTPInput
//           placeholder="* * * * *"
//           value={otp}
//           onChange={({ target }) => setOtp(target.value)}
//           maxLength={6}
//           handleClick={handleReSendOTP}
//           disabled={otpDisabled}
//           countdownTimer={seconds}
//         />

//         {error && (
//           <Typography color="error" textAlign="center" mt={2}>
//             {error}
//           </Typography>
//         )}

//         <StyledFormButton
//           onClick={handleLogin}
//           sx={{ mt: 2 }}
//           type="submit"
//           disabled={loading}
//         >
//           {loading ? "Verifying..." : "LOGIN"}
//         </StyledFormButton>

//         <StyledFormButton
//           textColor={COLORS.black}
//           bgColor={COLORS.white}
//           hoverBGColor={COLORS.primaryLight}
//           onClick={handleSkip}
//           sx={{ mt: 1 }}
//         >
//           SKIP
//         </StyledFormButton>
//       </FormStyled>
//     </Box>
//   );
// }

// export default OTPVerify;

// const StyledImg = styled.img({
//   width: 300,
//   height: 300,
//   objectFit: "contain",
//   margin: "10px 0",
// });

// const FormStyled = styled.form({
//   padding: "25px",
// });


import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import OTPInput from "../../../components/UI/Input/OTPInput";
import PhoneNumberInput from "../../../components/UI/Input/PhoneNumberInput";
import {
  userSentOtp,
  userVerifyOtp,
} from "../../../services/api_calls/auth/AuthApi";
import { COLORS } from "../../../styles/Theme";
import { useDispatch, useSelector } from "react-redux";
import { clearError, verifyOTP } from "../../../redux/auth/AuthSlice";

function OTPVerify() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, isLoggedIn, userData } = useSelector((state) => state.auth);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");

  const [seconds, setSeconds] = useState(0);
  const [startTimer, setStartTimer] = useState(false);

  const [numberDisabled, setNumberDisabled] = useState(false);
  const [otpDisabled, setOtpDisabled] = useState(true);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(clearError());
      }, 3000);
    }
  }, [error, dispatch]);

  // Store mobile number in localStorage
  const storeMobileNumber = (number) => {
    try {
      localStorage.setItem('userMobileNumber', number);
      console.log('Mobile number stored successfully:', number);
    } catch (err) {
      console.error('Error storing mobile number:', err);
    }
  };

  const handleSendOTP = async (event) => {
    event.preventDefault();
    if (mobileNumber) {
      const response = await userSentOtp({ mobileNumber });
      if (response.status) {
        // Store mobile number when OTP is sent
        storeMobileNumber(mobileNumber);
        setOtpDisabled(false);
        setNumberDisabled(true);
        setSeconds(30);
        setStartTimer(true);
      }
    }
  };

  const handleReSendOTP = async (event) => {
    const response = await userSentOtp({ mobileNumber });
    setSeconds(30);
    setStartTimer(true);
  };

  const handleEditNumber = () => {
    setNumberDisabled(false);
    setOtpDisabled(true);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    if (otp && mobileNumber) {
      try {
        const resultAction = await dispatch(verifyOTP({ 
          mobilenumber: mobileNumber,
          otp: otp 
        })).unwrap();
        
        // Store mobile number
        localStorage.setItem('userMobileNumber', mobileNumber);
        
        if (resultAction.user && resultAction.user.is_registered) {
          navigate("/home");
        } else {
          navigate("/registerDetails", {
            state: { 
              phoneNumber: mobileNumber
            }
          });
        }
      } catch (err) {
        console.error("Failed to verify OTP:", err);
      }
    }
  };

  const handleSkip = () => {
    navigate("/home");
  };

  useEffect(() => {
    let intervalId;
    if (startTimer) {
      intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          clearInterval(intervalId);
          setStartTimer(false);
        }
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [startTimer, seconds]);

  // Handle mobile number input change
  const handleMobileNumberChange = ({ target }) => {
    const value = target.value;
    setMobileNumber(value);
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <StyledImg component="img" src="/./assets/images/auth-img.png" />

        <Typography
          variant="h2"
          fontSize={18}
          fontWeight={600}
          color={COLORS.blackShade1}
          textAlign="center"
        >
          WELCOME TO LOCAL PRO
        </Typography>

        <Typography
          variant="body1"
          fontSize={13}
          fontWeight={500}
          color={COLORS.grey}
          textAlign="center"
        >
          Enter OTP To Continue
        </Typography>
      </Box>

      <FormStyled>
        <PhoneNumberInput
          placeholder="Enter Mobile Number"
          value={mobileNumber}
          onChange={handleMobileNumberChange}
          handleClick={handleSendOTP}
          handleEditClick={handleEditNumber}
          disabled={numberDisabled}
          btnDisabled={mobileNumber.length !== 10}
        />

        <OTPInput
          placeholder="* * * *"
          value={otp}
          onChange={({ target }) => setOtp(target.value)}
          maxLength={6}
          handleClick={handleReSendOTP}
          disabled={otpDisabled}
          countdownTimer={seconds}
        />

        {error && (
          <Typography color="error" textAlign="center" mt={2}>
            {error}
          </Typography>
        )}

        <StyledFormButton
          onClick={handleLogin}
          sx={{ mt: 2 }}
          type="submit"
          disabled={loading || otp.length !== 4}
        >
          {loading ? "Verifying..." : "LOGIN"}
        </StyledFormButton>

        <StyledFormButton
          textColor={COLORS.black}
          bgColor={COLORS.white}
          hoverBGColor={COLORS.primaryLight}
          onClick={handleSkip}
          sx={{ mt: 1 }}
        >
          SKIP
        </StyledFormButton>
      </FormStyled>
    </Box>
  );
}

export default OTPVerify;

const StyledImg = styled.img({
  width: 300,
  height: 300,
  objectFit: "contain",
  margin: "10px 0",
});

const FormStyled = styled.form({
  padding: "25px",
});