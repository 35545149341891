// src/components/UI/Footer/PrivacyPolicy.js
import React from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  styled,
  useMediaQuery,
  ThemeProvider,
  createTheme
} from '@mui/material';
import Header from "../DashboardHeader/Header";
import BottomTabs from '../BottomTabs';
import Footer from './Footer';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(5),
  margin: theme.spacing(2.5, 0),
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2.5),
    margin: theme.spacing(1.25, 0),
  },
}));

const ResponsiveContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(15),
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(3.75),
    paddingLeft: theme.spacing(1.875),
    paddingRight: theme.spacing(1.875),
    paddingBottom: theme.spacing(1.875),
  },
  [theme.breakpoints.between('sm', 'md')]: {
    padding: theme.spacing(10),
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: '#21608F',
  fontWeight: '600',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(3),
  fontSize: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.25rem',
  },
}));

const ContentText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: '#333',
  lineHeight: 1.6,
  fontSize: '1rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem',
  },
}));

const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          maxWidth: "1200px",
        },
      },
    },
  },
});

const PrivacyPolicy = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
    <Header />
    <ResponsiveContainer  maxWidth="lg">
      
    <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 'bold',
            color: '#21608F',
            mb: 3,
            textAlign: 'center',
            fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' },
          }}
        >
          Privacy Policy
        </Typography>

        <StyledPaper>
          <ContentText>
            Last updated: January 31, 2025
          </ContentText>

          <SectionTitle variant="h5">
            1. Introduction
          </SectionTitle>
          <ContentText>
            Welcome to Local Pro. We respect your privacy and are committed to protecting your personal data. 
            This privacy policy explains how we collect, use, disclose, and safeguard your information when 
            you use our service.
          </ContentText>

          <SectionTitle variant="h5">
            2. Information We Collect
          </SectionTitle>
          <ContentText>
            We collect several types of information, including:
          </ContentText>
          <Box sx={{ pl: 2, mb: 2 }}>
            <ContentText>
              • Personal Information: Name, email address, phone number, postal address
            </ContentText>
            <ContentText>
              • Usage Data: IP address, browser type, pages visited, time and date of visit
            </ContentText>
            <ContentText>
              • Service Provider Information: Professional credentials, service history, reviews
            </ContentText>
          </Box>

          <SectionTitle variant="h5">
            3. How We Use Your Information
          </SectionTitle>
          <ContentText>
            We use the collected information for various purposes:
          </ContentText>
          <Box sx={{ pl: 2, mb: 2 }}>
            <ContentText>
              • To provide and maintain our service
            </ContentText>
            <ContentText>
              • To notify you about changes to our service
            </ContentText>
            <ContentText>
              • To provide customer support
            </ContentText>
            <ContentText>
              • To gather analysis or valuable information to improve our service
            </ContentText>
            <ContentText>
              • To detect, prevent and address technical issues
            </ContentText>
          </Box>

          <SectionTitle variant="h5">
            4. Information Sharing and Disclosure
          </SectionTitle>
          <ContentText>
            We may share your personal information with:
          </ContentText>
          <Box sx={{ pl: 2, mb: 2 }}>
            <ContentText>
              • Service providers to facilitate our service
            </ContentText>
            <ContentText>
              • Business partners with your consent
            </ContentText>
            <ContentText>
              • Law enforcement when required by law
            </ContentText>
          </Box>

          <SectionTitle variant="h5">
            5. Data Security
          </SectionTitle>
          <ContentText>
            We implement appropriate security measures to protect against unauthorized access, 
            alteration, disclosure, or destruction of your information. However, no method of 
            transmission over the Internet is 100% secure.
          </ContentText>

          <SectionTitle variant="h5">
            6. Your Data Rights
          </SectionTitle>
          <ContentText>
            You have the right to:
          </ContentText>
          <Box sx={{ pl: 2, mb: 2 }}>
            <ContentText>
              • Access your personal data
            </ContentText>
            <ContentText>
              • Correct inaccurate data
            </ContentText>
            <ContentText>
              • Request deletion of your data
            </ContentText>
            <ContentText>
              • Object to processing of your data
            </ContentText>
            <ContentText>
              • Request transfer of your data
            </ContentText>
          </Box>

          <SectionTitle variant="h5">
            7. Cookies Policy
          </SectionTitle>
          <ContentText>
            We use cookies and similar tracking technologies to track activity on our service 
            and hold certain information. You can instruct your browser to refuse all cookies 
            or to indicate when a cookie is being sent.
          </ContentText>

          <SectionTitle variant="h5">
            8. Changes to This Privacy Policy
          </SectionTitle>
          <ContentText>
            We may update our Privacy Policy from time to time. We will notify you of any 
            changes by posting the new Privacy Policy on this page and updating the "Last updated" 
            date at the top of this policy.
          </ContentText>

          <SectionTitle variant="h5">
            9. Contact Us
          </SectionTitle>
          <ContentText>
            If you have any questions about this Privacy Policy, please contact us:
          </ContentText>
          <Box sx={{ pl: { xs: 1, sm: 2 } }}>
            <ContentText>
              • By email: support@localpro.com
            </ContentText>
            <ContentText>
              • By phone: 90909 09090
            </ContentText>
          </Box>
        </StyledPaper>
    </ResponsiveContainer >
    {isMobile && <BottomTabs activeTab="privacy" />}
    <Footer />
   </ThemeProvider>
  );
};

export default PrivacyPolicy;