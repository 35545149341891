import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { COLORS } from "../../../styles/Theme";
import {
  CloseButton,
  ProfileContainer,
  ProfileMenu,
} from "./UserProfile.style";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import ProfileImgWithEdit from "./ProfileImgWithEdit";
import { useNavigate } from "react-router-dom";
import { getProfileData } from "../../../redux/profile/profileSlice";
import ListNowModal from "../../Dashboard/Home/ListNowModal";
import { getRegisterApi } from '../../../services/api_calls/auth/AuthApi'
import { useDispatch, useSelector } from "react-redux";
import { logout,fetchUserProfile } from "../../../redux/auth/AuthSlice";

function UserProfile({ openModal }) {
  const navigate = useNavigate();
  // const [userData, setUserData] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const dispatch = useDispatch();
  const [listNowPopup, setListNowPopup] = useState(false);
  const dispatch = useDispatch();
  const { isLoggedIn, userData, loading } = useSelector((state) => state.auth);
  const defaultProfileImg =
    "https://images.pexels.com/photos/428361/pexels-photo-428361.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

  const [profileImg, setProfileImg] = useState(defaultProfileImg);
  const handleOpenListPopup = () => {
    setListNowPopup(true);
  };
  const handleCloseListPopup = () => {
    setListNowPopup(false); // Set popup state to false to close it
  };
  useEffect(() => {
    if (isLoggedIn && !userData) {
      dispatch(fetchUserProfile());
    }
  }, [isLoggedIn, userData, dispatch]);
  const handleMenuClick = (route) => {
    navigate(route);
  };
  const handleLogout = () => {
    dispatch(logout());
    navigate('/home');
  };
  const handleCloseProfile = () => {
    navigate('/home');
  };

  const handleEditProfile = () => {
    navigate('/registerDetails', { state: { isEditing: true } });
  };
  // const { profileContent, profileContentLoader } = useSelector(
  //   (state) => state.profile
  // );

  // useEffect(() => {
  //   dispatch(getProfileData());
  //   return () => {};
  // }, []);
  // const fetchUserProfile = async () => {
  //   try {
  //     const response = await getRegisterApi();
  //     if (response && response.user) {
  //       setUserData(response.user);
  //       if (response.user.profilePicture) {
  //         setProfileImg(response.user.profilePicture);
  //       } else {
  //         setProfileImg(defaultProfileImg);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching profile:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const handleProfileUpdate = async (newImage) => {
    setProfileImg(newImage);
    // Refresh the user data after profile update
    await fetchUserProfile();
  };
  // useEffect(() => {
  //   // Check if user is logged in when component mounts
  //   const token = localStorage.getItem('token');
  //   setIsLoggedIn(!!token);
  // }, []);
  // const handleLogout = () => {
  //   // Clear user data and token
  //   localStorage.removeItem('token');
  //   setIsLoggedIn(false);
  //   setUserData(null);
  //   setProfileImg(defaultProfileImg);
  //   navigate('/home'); // Redirect to login page
  // };

  if (loading) {
    return <Box>Loading...</Box>; // You can replace this with a proper loading component
  }
  return (
    <Box>
      <ProfileContainer>
        <Typography
          variant="h2"
          fontSize={18}
          fontWeight={500}
          color={COLORS.white}
          textAlign="center"
        >
          Profile
        </Typography>

        <ProfileImgWithEdit
          image={userData?.profilePicture || defaultProfileImg}
          onEdit={handleEditProfile}
          editable={isLoggedIn}
        />


        <Typography
          variant="h6"
          fontSize={18}
          fontWeight={500}
          color={COLORS.white}
          textAlign="center"
        >
          {userData?.name || ""}
        </Typography>

        <Typography
          variant="body2"
          fontSize={13}
          fontWeight={400}
          color={COLORS.white}
          textAlign="center"
        >
          {userData?.mobile ? `+91 ${userData.mobile}` : "Mobile not available"}
          <br />
          {userData?.email || "Email not available"}
        </Typography>

        <CloseButton onClick={handleCloseProfile}>
          <CloseRoundedIcon />
        </CloseButton>
      </ProfileContainer>

      <ProfileMenu>
        <MenuList>
          {/* <ProfileMenuItem
            icon="/./assets/images/profile-icons/person.png"
            label="Edit Profile"
            onClick={() => {
              handleMenuClick("/addPersonalDetails");
            }}
          /> */}

          <ProfileMenuItem
            icon="/./assets/images/profile-icons/business.png"
            label="List Your Business"
            // onClick={openModal}
            onClick={() => {
              handleMenuClick("/download");
            }}
          />

          {/* <ProfileMenuItem
            icon="/./assets/images/profile-icons/subscription.png"
            label="My Subscriptions"
            infoText="Pro Contact Balance: 100"
            onClick={() => {
              handleMenuClick('/mySubscriptions');
            }}
          /> */}

          <ProfileMenuItem
            icon="/./assets/images/profile-icons/about.png"
            label="About Us"
            onClick={() => {
              handleMenuClick("/about");
            }}
            // onClick={() => {
            //   handleMenuClick();
            // }}
          />

          {/* <ProfileMenuItem
            icon="/./assets/images/profile-icons/send-feedback.png"
            label="Send Feedback"
            onClick={() => {
              handleMenuClick();
            }}
          /> */}

          <ProfileMenuItem
            icon="/./assets/images/profile-icons/rate-us.png"
            // label="Rate Us On Playstore"
            label="Get App"
            onClick={openModal}
            // onClick={() => {
            //   handleMenuClick();
            // }}
          />

          <ProfileMenuItem
            icon="/./assets/images/profile-icons/terms-condition.png"
            label="Term & Conditions"
            onClick={() => {
              handleMenuClick("/termAndConditions");
            }}
          />

          {/* <ProfileMenuItem
            icon="/./assets/images/profile-icons/privacy-policy.png"
            label="Privacy Policy"
            onClick={() => {
              handleMenuClick();
            }}
          /> */}

          <ProfileMenuItem
            icon="/./assets/images/profile-icons/help-center.png"
            label="Help Center (Contact Us)"
            onClick={() => {
              handleMenuClick("/helpCenter");
            }}
          />

          {/* <ProfileMenuItem
            icon="/./assets/images/profile-icons/share.png"
            label="Share App"
            onClick={() => {
              handleMenuClick();
            }}
          /> */}

          {/* <ProfileMenuItem
            icon="/./assets/images/profile-icons/logout.png"
            label="Logout"
            onClick={() => {
              handleMenuClick();
            }}
          /> */}
           {isLoggedIn ? (
            <ProfileMenuItem
              icon="/./assets/images/profile-icons/logout.png"
              label="Logout"
              onClick={handleLogout}
            />
          ) : (
            <ProfileMenuItem
              icon="/./assets/images/profile-icons/login.png"
              label="Login/Signup"
              onClick={() => handleMenuClick("/otpVerify")}
            />
          )}
        </MenuList>
      </ProfileMenu>
    </Box>
  );
}

export default UserProfile;

const ProfileMenuItem = ({ icon, label, infoText, onClick }) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <Box
          component="img"
          src={icon}
          sx={{ width: 20, height: 20, objectFit: "contain" }}
        />
      </ListItemIcon>

      <ListItemText>
        <Typography variant="body2" fontSize={14}>
          {label}
        </Typography>
      </ListItemText>

      <Box display="flex" alignItems="center">
        {infoText && (
          <Typography
            variant="body2"
            fontSize={10}
            fontWeight={500}
            color={COLORS.primaryColor}
          >
            {infoText}
          </Typography>
        )}
        <KeyboardArrowRightRoundedIcon sx={{ color: COLORS.greyShade3 }} />
      </Box>
    </MenuItem>
  );
};
