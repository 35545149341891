// src/components/UI/Footer/TermsAndConditions.js
import React from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  styled,
  useMediaQuery,
  ThemeProvider,
  createTheme,
  useTheme
} from '@mui/material';
import Header from "../DashboardHeader/Header";
import BottomTabs from '../BottomTabs';
import Footer from './Footer';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '40px',
  margin: '20px 0',
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  [theme.breakpoints.down('md')]: {
    padding: '30px',
    margin: '15px 0',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
    margin: '10px 0',
  },
}));

const ResponsiveContainer = styled(Container)(({ theme }) => ({
  padding: '80px',
  [theme.breakpoints.down('md')]: {
    padding: '40px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: '30px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '15px',
  },
}));

const SectionTitle = styled(Typography)({
  color: '#21608F',
  fontWeight: '600',
  marginBottom: '16px',
  marginTop: '24px',
});

const ContentText = styled(Typography)({
  marginBottom: '16px',
  color: '#333',
  lineHeight: 1.6,
});

const ListItem = styled(Box)({
  marginBottom: '12px',
  paddingLeft: '20px',
  position: 'relative',
  '&:before': {
    content: '"•"',
    position: 'absolute',
    left: '0',
    color: '#21608F',
  },
});

const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          maxWidth: "1200px",
        },
      },
    },
  },
});

const TermsAndConditions = () => {
  const globalTheme = useTheme();
  const isMobile = useMediaQuery(globalTheme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(globalTheme.breakpoints.down('md'));

  return (
    <ThemeProvider theme={theme}>
    <Header />
    <ResponsiveContainer maxWidth="lg">
    <Box sx={{ py: isMobile ? 3 : isTablet ? 4 : 5 }}>
    <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 'bold',
              color: '#21608F',
              mb: 3,
              textAlign: 'center',
              fontSize: isMobile ? '24px' : isTablet ? '28px' : '32px'
            }}
          >
          Terms and Conditions
        </Typography>

        <StyledPaper>
        <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
            Last updated: January 31, 2025
          </ContentText>

          <SectionTitle variant="h5" sx={{ fontSize: isMobile ? '18px' : '22px' }}>
            1. Agreement to Terms
          </SectionTitle>
         <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
            By accessing or using Local Pro's services, you agree to be bound by these Terms and Conditions. 
            If you disagree with any part of these terms, you may not access our service.
          </ContentText>

          <SectionTitle variant="h5" sx={{ fontSize: isMobile ? '18px' : '22px' }}>
            2. Services Description
          </SectionTitle>
         <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
            Local Pro is a platform connecting users with local service providers. We:
          </ContentText>
          <Box sx={{ pl: 2, mb: 2 }}>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                Facilitate connections between service providers and customers
              </ContentText>
            </ListItem>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                Provide a platform for reviewing and rating service providers
              </ContentText>
            </ListItem>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                Enable service booking and scheduling
              </ContentText>
            </ListItem>
          </Box>

          <SectionTitle variant="h5" sx={{ fontSize: isMobile ? '18px' : '22px' }}>
            3. User Accounts
          </SectionTitle>
         <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
            When you create an account with us, you must provide accurate, complete, and current information. 
            You are responsible for:
          </ContentText>
          <Box sx={{ pl: 2, mb: 2 }}>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                Maintaining the confidentiality of your account credentials
              </ContentText>
            </ListItem>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                All activities that occur under your account
              </ContentText>
            </ListItem>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                Notifying us immediately of any unauthorized use
              </ContentText>
            </ListItem>
          </Box>

          <SectionTitle variant="h5" sx={{ fontSize: isMobile ? '18px' : '22px' }}>
            4. Service Provider Terms
          </SectionTitle>
         <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
            Service providers must:
          </ContentText>
          <Box sx={{ pl: 2, mb: 2 }}>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                Maintain valid licenses and certifications as required
              </ContentText>
            </ListItem>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                Provide accurate service descriptions and pricing
              </ContentText>
            </ListItem>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                Maintain professional conduct and quality service standards
              </ContentText>
            </ListItem>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                Respond to service requests in a timely manner
              </ContentText>
            </ListItem>
          </Box>

          <SectionTitle variant="h5" sx={{ fontSize: isMobile ? '18px' : '22px' }}>
            5. User Responsibilities
          </SectionTitle>
         <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
            Users agree to:
          </ContentText>
          <Box sx={{ pl: 2, mb: 2 }}>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                Provide accurate information when booking services
              </ContentText>
            </ListItem>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                Honor scheduled appointments
              </ContentText>
            </ListItem>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                Provide honest and fair reviews
              </ContentText>
            </ListItem>
          </Box>

          <SectionTitle variant="h5" sx={{ fontSize: isMobile ? '18px' : '22px' }}>
            6. Payment Terms
          </SectionTitle>
         <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
            All payments through the platform are subject to:
          </ContentText>
          <Box sx={{ pl: 2, mb: 2 }}>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                Platform service fees and processing charges
              </ContentText>
            </ListItem>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                Cancellation and refund policies
              </ContentText>
            </ListItem>
            <ListItem>
             <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
                Local tax regulations
              </ContentText>
            </ListItem>
          </Box>

          <SectionTitle variant="h5" sx={{ fontSize: isMobile ? '18px' : '22px' }}>
            7. Intellectual Property
          </SectionTitle>
         <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
            The service and its original content, features, and functionality are owned by 
            Local Pro and are protected by international copyright, trademark, patent, trade 
            secret, and other intellectual property laws.
          </ContentText>

          <SectionTitle variant="h5" sx={{ fontSize: isMobile ? '18px' : '22px' }}>
            8. Limitation of Liability
          </SectionTitle>
         <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
            Local Pro shall not be liable for any indirect, incidental, special, consequential, 
            or punitive damages resulting from your use of or inability to use the service.
          </ContentText>

          <SectionTitle variant="h5" sx={{ fontSize: isMobile ? '18px' : '22px' }}>
            9. Changes to Terms
          </SectionTitle>
         <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
            We reserve the right to modify or replace these terms at any time. Changes will 
            be effective immediately upon posting to the platform. Continued use of the service 
            constitutes acceptance of the modified terms.
          </ContentText>

          <SectionTitle variant="h5" sx={{ fontSize: isMobile ? '18px' : '22px' }}>
            10. Contact Information
          </SectionTitle>
         <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
            For questions about these Terms and Conditions, please contact us:
          </ContentText>
          <Box sx={{ pl: 2 }}>
           <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
              Email: support@localpro.com
            </ContentText>
           <ContentText sx={{ fontSize: isMobile ? '14px' : '16px' }}>
              Phone: 90909 09090
            </ContentText>
          </Box>
        </StyledPaper>
      </Box>
    </ResponsiveContainer>
    {isMobile && <BottomTabs activeTab="terms" />}
    <Footer />
   </ThemeProvider>
  );
};

export default TermsAndConditions;