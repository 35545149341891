import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser, clearError } from "../../../redux/auth/AuthSlice";
import { Box, Typography, Avatar, Select, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import Header from "../../../components/UI/Header";
import InputText from "../../../components/UI/Input/InputText";
import EditIcon from "@mui/icons-material/Edit";
import { getS3Url } from "../../../services/api_calls/auth/AuthApi";
import { COLORS } from "../../../styles/Theme";
import styled from "@emotion/styled";

function RegisterDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { loading, error: authError, userData, isLoggedIn } = useSelector((state) => state.auth);
  const isEditing = location.state?.isEditing || false;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    gender: "",
    photo: null,
    photoPreview: null,
    useWhatsApp: true
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (isEditing && userData) {
      setFormData({
        name: userData.name || "",
        email: userData.email || "",
        number: userData.mobile || "",
        gender: userData.gender || "",
        photo: null,
        photoPreview: userData.profilePicture || null,
        useWhatsApp: userData.whatsApp || true
      });
    } else {
      const storedNumber = localStorage.getItem('userMobileNumber');
      if (storedNumber) {
        setFormData(prev => ({
          ...prev,
          number: storedNumber
        }));
      }
    }
  }, [isEditing, userData]);

  useEffect(() => {
    if (authError) {
      setError(authError);
      setTimeout(() => {
        dispatch(clearError());
        setError("");
      }, 3000);
    }
  }, [authError, dispatch]);

  const uploadToS3 = async (file) => {
    try {
      if (!file) {
        throw new Error("No file provided");
      }
      if (file.size > 5 * 1024 * 1024) {
        throw new Error("File size should be less than 5MB");
      }
      const response = await getS3Url({ img: file });
      if (!response?.status || !response?.data) {
        throw new Error("Invalid S3 response");
      }
      return response.data;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };

  const isValidEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.number) {
      setError("Please fill in all required fields");
      return false;
    }
    if (!isValidEmail(formData.email)) {
      setError("Please enter a valid email address");
      return false;
    }
    if (formData.number.length !== 10) {
      setError("Please enter a valid 10-digit mobile number");
      return false;
    }
    return true;
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setError("File size should be less than 5MB");
        return;
      }
      if (!file.type.startsWith('image/')) {
        setError("Please select an image file");
        return;
      }
      setFormData(prev => ({
        ...prev,
        photo: file,
        photoPreview: URL.createObjectURL(file)
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      let profilePicture = formData.photo ? await uploadToS3(formData.photo) : (userData?.profilePicture || "photo");

      const userDataToSubmit = {
        name: formData.name,
        email: formData.email,
        mobile: formData.number,
        whatsApp: formData.useWhatsApp,
        profilePicture,
        gender: formData.gender || "not_specified",
      };

      const resultAction = await dispatch(registerUser(userDataToSubmit)).unwrap();
      if (resultAction?.status) {
        navigate("/home");
      } else {
        setError(resultAction?.message || `${isEditing ? "Update" : "Registration"} failed. Please try again.`);
      }
    } catch (err) {
      console.error(`${isEditing ? "Update" : "Registration"} failed:`, err);
      setError("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
    setError("");
  };

  const handleWhatsAppChange = (event) => {
    setFormData(prev => ({
      ...prev,
      useWhatsApp: event.target.checked
    }));
  };

  return (
    <Box>
      <Header title="" disableShadow />

      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography
          variant="h2"
          fontSize={18}
          fontWeight={600}
          color={COLORS.blackShade1}
          textAlign="center"
        >
          {isEditing ? "EDIT PROFILE" : "PERSONAL DETAILS"}
        </Typography>

        <Typography
          variant="body1"
          fontSize={14}
          fontWeight={400}
          color={COLORS.grey}
          textAlign="center"
          mt={0.8}
        >
          {isEditing ? "Update Your Personal Details" : "Enter Your Personal Details To Continue"}
        </Typography>
      </Box>

      <FormStyled onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="center" mb={4} position="relative">
          <input
            type="file"
            accept="image/*"
            id="profile-photo"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
          <label
            htmlFor="profile-photo"
            style={{ position: "relative", display: "inline-block" }}
          >
            <StyledAvatar src={formData.photoPreview}>
              {!formData.photoPreview && ""}
            </StyledAvatar>
            <StyledEditIcon>
              <EditIcon sx={{ fontSize: 16, color: "white" }} />
            </StyledEditIcon>
          </label>
        </Box>

        <InputText
          placeholder="Enter Your Full Name"
          value={formData.name}
          onChange={handleInputChange("name")}
          required
        />

        <InputText
          placeholder="Enter Your Email ID"
          value={formData.email}
          onChange={handleInputChange("email")}
          type="email"
          required
        />

        <InputText
          placeholder="Enter Your Mobile Number"
          value={formData.number}
          onChange={handleInputChange("number")}
          type="tel"
          inputProps={{
            maxLength: 10,
            pattern: "[0-9]*"
          }}
          required
        />

        <StyledSelect
          value={formData.gender}
          onChange={handleInputChange("gender")}
          displayEmpty
          fullWidth
        >
          <MenuItem value="" disabled>
            Select Gender
          </MenuItem>
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </StyledSelect>
        
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.useWhatsApp}
              onChange={handleWhatsAppChange}
              color="primary"
            />
          }
          label="Use this number for WhatsApp"
        />

        {error && (
          <Typography color="error" textAlign="center" mt={2}>
            {error}
          </Typography>
        )}

        <StyledFormButton 
          type="submit" 
          disabled={isLoading}
          sx={{ mt: 2 }}
        >
          {isLoading ? (isEditing ? "Updating..." : "Registering...") : (isEditing ? "Update" : "Continue")}
        </StyledFormButton>
      </FormStyled>
    </Box>
  );
}

export default RegisterDetails;

const FormStyled = styled.form({
  padding: "25px",
});

const StyledAvatar = styled(Avatar)({
  width: "120px",
  height: "120px",
  background: "#f5f5f5",
  cursor: "pointer",
  position: "relative",
  margin: "0 auto",
});

const StyledEditIcon = styled.div({
  position: "absolute",
  right: "0px",
  top: "20%",
  transform: "translateY(-50%)",
  backgroundColor: "#FFA500",
  borderRadius: "50%",
  width: "28px",
  height: "28px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  zIndex: 1,
});

const StyledSelect = styled(Select)({
  height: '45px',
  marginBottom: '16px',
  background: '#f8f8f8',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent'
  },
  '& .MuiSelect-select': {
    padding: '10px 15px',
    color: '#666',
    fontSize: '14px'
  }
});