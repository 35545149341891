import React, { useState } from "react";
import {
  Box,
  Typography
} from "@mui/material";
import {
  DashboardHeaderContainer,
  FlexBox,
} from "./DownloadStyle";

const Header = () => {
  // State to track active menu item
  const [activeItem, setActiveItem] = useState("HOME");
  
  // Navigation items with corresponding section IDs
  const navItems = [
    { name: "HOME", section: "home" },
    { name: "SERVICES", section: "popular-services" },
    { name: "ABOUT US", section: "about-us" },
  ];

  const handleNavClick = (item) => {
    // Update active menu item
    setActiveItem(item.name);
    
    // Scroll to the section
    const element = document.getElementById(item.section);
    if (element) {
      // Add a small animation to highlight the section being navigated to
      element.style.transition = "box-shadow 0.5s ease";
      element.style.boxShadow = "0 0 20px rgba(255, 138, 0, 0.5)";
      
      // Scroll to the element
      element.scrollIntoView({ 
        behavior: "smooth",
        block: "start"
      });
      
      // Remove the highlight effect after scrolling completes
      setTimeout(() => {
        element.style.boxShadow = "none";
      }, 1000);
    }
  };

  // Handle scroll events to update active menu item
  React.useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      
      // Check which section is currently in view
      navItems.forEach(item => {
        const section = document.getElementById(item.section);
        if (section) {
          const offset = section.offsetTop;
          const height = section.offsetHeight;
          
          if (scrollPosition >= offset - 100 && scrollPosition < offset + height - 100) {
            setActiveItem(item.name);
          }
        }
      });
    };
    
    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);
    
    // Initial check on mount
    handleScroll();
    
    // Clean up event listener
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <DashboardHeaderContainer>
      <FlexBox sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Box
          component="img"
          src="/./assets/images/logo.jpg"
          alt="Local Pro"
          width={122}
          height={58}
          sx={{ 
            objectFit: "contain", 
            cursor: "pointer",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)"
            }
          }}
          onClick={() => handleNavClick(navItems[0])}
        />
        <FlexBox>
          {navItems.map((item, index) => (
            <Typography 
              key={index}
              onClick={() => handleNavClick(item)}
              sx={{
                mx: 2,
                fontWeight: 600,
                fontSize: "13px",
                cursor: "pointer",
                color: activeItem === item.name ? "#ff8a00" : "#002e5b",
                borderBottom: activeItem === item.name ? "2px solid #ff8a00" : "2px solid transparent",
                padding: "5px 0",
                transition: "all 0.3s ease",
                position: "relative",
                "&:hover": {
                  color: "#ff8a00",
                  transform: "translateY(-2px)"
                },
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: "-2px",
                  left: "50%",
                  width: activeItem === item.name ? "100%" : "0%",
                  height: "2px",
                  backgroundColor: "#ff8a00",
                  transition: "all 0.3s ease",
                  transform: "translateX(-50%)"
                },
                "&:hover::after": {
                  width: "100%"
                }
              }}
            >
              {item.name}
            </Typography>
          ))}
        </FlexBox>
      </FlexBox>
    </DashboardHeaderContainer>
  );
};

export default Header;