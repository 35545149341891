import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  Box,
  Typography,
  Grid,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { getCategoryDataApi } from '../../../services/api_calls/auth/AuthApi';
import Header from "../DashboardHeader/Header";
import BottomTabs from '../BottomTabs';
import { createUrlSlug } from '../../../utils/urlUtils';
import styled from '@emotion/styled';
import Footer from './Footer';

const Services = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [categories, setCategories] = useState([]);

  const customTheme = createTheme({
    components: {
      MuiContainer: {
        styleOverrides: {
          maxWidthLg: {
            maxWidth: "1200px",
          },
        },
      },
    },
  });

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategoryDataApi();
        if (response?.status && response?.categories) {
          const sortedCategories = response.categories.sort((a, b) => a.position - b.position);
          setCategories(sortedCategories);
        }
      } catch (err) {
        console.error("Error fetching categories:", err);
      }
    };
    fetchCategories();
  }, []);

  const handleServiceClick = (subCategory) => {
    if (!subCategory?.name) return;

    const serviceSlug = createUrlSlug(subCategory.name);
    const currentCity = localStorage.getItem('selectedCity');
    const currentCitySlug = currentCity ? createUrlSlug(currentCity) : '';

    const navigationPath = currentCitySlug 
      ? `/${currentCitySlug}/${serviceSlug}`
      : `/${serviceSlug}`;

    navigate(navigationPath, {
      state: {
        selectedService: subCategory.name,
        selectedServiceId: subCategory.id,
        cityName: currentCity
      }
    });
  };

  const ServiceContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3.5),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
      paddingTop: theme.spacing(15),
    },

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(7),
      paddingTop: theme.spacing(18),
    },
  }));

  const ServiceItem = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    padding: theme.spacing(2.5),
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'translateY(-5px)'
    },

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  }));

  const ServiceImage = styled(Box)(({ theme }) => ({
    width: '80px',
    height: '80px',
    marginBottom: theme.spacing(1.5),
    borderRadius: '50%',
    objectFit: 'cover',

    [theme.breakpoints.up('sm')]: {
      width: '90px',
      height: '90px',
    },

    [theme.breakpoints.up('md')]: {
      width: '100px',
      height: '100px',
      marginBottom: theme.spacing(2),
    },
  }));

  return (
    <ThemeProvider theme={customTheme}>
      <Header />
      
      <section className="service-main-container">
        <ServiceContainer className="container service-container">
          <Typography 
            variant="h4" 
            className="main-heading text-center fw-bold"
            sx={{ 
              mb: { xs: 3, sm: 4, md: 5 },
              color: '#21608F',
              fontWeight: 'bold',
              fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' },
            }}
          >
            Our Services
          </Typography>

          <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
            {categories.map((category) => (
              <React.Fragment key={category.id}>
                {category.subCategory?.map((service) => (
                  <Grid 
                    item 
                    xs={12} 
                    sm={6}
                    md={4} 
                    key={service.id}
                    className="work-container-subdiv"
                  >
                    <ServiceItem onClick={() => handleServiceClick(service)}>
                      <ServiceImage
                        component="img"
                        src={service.image}
                        alt={service.name}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "./assets/images/home-banner.png";
                        }}
                      />
                      
                      <Typography 
                        variant="h6" 
                        className="sub-heading"
                        sx={{ 
                          mb: { xs: 1, sm: 1.5, md: 2 },
                          color: '#21608F',
                          fontWeight: '600',
                          fontSize: { xs: '1.1rem', sm: '1.2rem', md: '1.25rem' },
                        }}
                      >
                        {service.name}
                      </Typography>

                      <Typography 
                        className="main-hero-para"
                        sx={{ 
                          color: '#666',
                          fontSize: { xs: '0.8rem', sm: '0.85rem', md: '0.9rem' },
                          lineHeight: 1.6,
                        }}
                      >
                        {service.description || `Professional ${service.name} services at your doorstep`}
                      </Typography>
                    </ServiceItem>
                  </Grid>
                ))}
              </React.Fragment>
            ))}
          </Grid>
        </ServiceContainer>
      </section>

      {isMobile && <BottomTabs activeTab="services" />}
      <Footer />
    </ThemeProvider>
  );
};

export default Services;