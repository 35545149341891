// utils/axios.js
import axios from 'axios';
const baseAPI = process.env.NEXT_PUBLIC_API_URL || 'http://127.0.0.1:8000';
const baseURL = baseAPI.endsWith('/v1') ? baseAPI : baseAPI + '/v1';

console.log("Axios using baseURL:", baseURL);

const axiosServices = axios.create({
  baseURL,
  withCredentials: true,
});

axiosServices.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    
    // For debug purposes - log the full URL being requested
    console.log(`Making ${config.method?.toUpperCase() || 'GET'} request to: ${config.baseURL}${config.url}`, 
      config.params ? `with params: ${JSON.stringify(config.params)}` : '');
    
    return config;
  },
  (error) => Promise.reject(error)
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized access');
    }
    
    // Log detailed error information
    if (error.response) {
      console.error(`Error response from ${error.config?.url || 'unknown endpoint'}:`, {
        status: error.response.status,
        data: error.response.data
      });
    } else if (error.request) {
      console.error('No response received from server:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }
    
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

export default axiosServices;