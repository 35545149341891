import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Card,
  styled,
  useMediaQuery,
  ThemeProvider,
  createTheme,
  Snackbar,
  Alert,
} from "@mui/material";
import BottomTabs from "../BottomTabs";
import Header from "../DashboardHeader/Header";
import Footer from "./Footer";
import { createContactApi } from "../../../services/api_calls/auth/AuthApi";

const PageContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(15, 2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 2),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 4),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(15, 8),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
    },
    "&:hover fieldset": {
      borderColor: "#21608F",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#21608F",
    },
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#21608F",
  padding: theme.spacing(1.5),
  fontSize: "1rem",
  "&:hover": {
    backgroundColor: "#184569",
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: "0.9rem",
    padding: theme.spacing(1),
  },
}));

const ContactUs = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
    agreeToTerms: false,
  });

  const handleChange = (event) => {
    const { name, value, checked } = event.target;

    if (name === "phone") {
      const phoneNumber = value.replace(/\D/g, "").slice(0, 10);
      setFormData((prevState) => ({
        ...prevState,
        [name]: phoneNumber,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: name === "agreeToTerms" ? checked : value,
      }));
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await createContactApi(formData);

      if (response.success) {
        setSnackbar({
          open: true,
          message: "Message sent successfully!",
          severity: "success",
        });

        setFormData({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          message: "",
          agreeToTerms: false,
        });
      } else {
        throw new Error(response.message || "Failed to submit form");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSnackbar({
        open: true,
        message: error.message || "Error sending message. Please try again.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const theme = createTheme({
    components: {
      MuiContainer: {
        styleOverrides: {
          maxWidthLg: {
            maxWidth: "1200px",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <PageContainer maxWidth="lg">
        <Box mb={{ xs: 3, sm: 4, md: 6 }} textAlign="center">
          <Typography variant="h3" component="h1" gutterBottom sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }}>
            Contact Us
          </Typography>
          <Typography variant="h6" color="textSecondary" paragraph sx={{ fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' } }}>
            Get in Touch with Our Team - We're Here to Help
          </Typography>
        </Box>

        <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
          <Grid item xs={12} md={5}>
            <Box sx={{ mb: { xs: 3, md: 4 } }}>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", color: "#21608F", mb: 2, fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' } }}
              >
                Connect With Our Team
              </Typography>
              <Typography sx={{ color: "#666", mb: 3, fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } }}>
                Have questions about our services? Need support? Our team is
                here to help you with anything you need.
              </Typography>

              <Card
                sx={{
                  p: { xs: 2, sm: 3 },
                  bgcolor: "#f8f9fa",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                  mb: 3,
                }}
              >
                <Typography variant="h6" sx={{ mb: 2, color: "#21608F", fontSize: { xs: '1.1rem', sm: '1.25rem' } }}>
                  Contact Information
                </Typography>
                <Typography sx={{ mb: 1, fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                  Email: support@localpro.com
                </Typography>
                <Typography sx={{ mb: 1, fontSize: { xs: '0.9rem', sm: '1rem' } }}>Phone: 90909 09090</Typography>
                <Typography sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                  Working Hours: Mon-Sat, 9:00 AM - 6:00 PM
                </Typography>
              </Card>
            </Box>
          </Grid>

          <Grid item xs={12} md={7}>
          <Card sx={{ p: { xs: 2, sm: 3 }, boxShadow: "0 2px 8px rgba(0,0,0,0.1)" }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <StyledTextField
                      fullWidth
                      name="firstName"
                      label="First Name"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledTextField
                      fullWidth
                      name="lastName"
                      label="Last Name"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledTextField
                      fullWidth
                      name="phone"
                      label="Phone Number"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                      inputProps={{
                        pattern: "[0-9]{10}",
                        maxLength: 10,
                        inputMode: "numeric",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledTextField
                      fullWidth
                      name="email"
                      label="Email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTextField
                      fullWidth
                      name="message"
                      label="Message"
                      multiline
                      rows={4}
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="agreeToTerms"
                          checked={formData.agreeToTerms}
                          onChange={handleChange}
                          required
                          sx={{
                            "&.Mui-checked": {
                              color: "#21608F",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}>
                          I agree that Local Pro may contact me at the email address or phone number above
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SubmitButton
                      type="submit"
                      variant="contained"
                      fullWidth
                      disabled={loading}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </SubmitButton>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      </PageContainer>
      {isMobile && <BottomTabs activeTab="contactus" />}
      <Footer />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default ContactUs;
