import React from "react";
import { Grid, Typography, IconButton, Box, Container } from "@mui/material";
import styled from "@emotion/styled";
import {
  MainFooter,
  FooterContainer,
  FooterLogo,
  FooterLink,
  FooterContact,
  CopyrightText,
  SocialIcons,
} from "./DownloadFooterstyle";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export const FooterContactStyled = styled(FooterContact)`
  line-height: 2.8;
`;

export const CircularIconButton = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  svg {
    color: #1d3557;
    font-size: 20px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  svg {
    color: #656464;
  }
`;

export const HiddenOnMobile = styled(Grid)`
  @media (max-width: 600px) {
    display: none;
  }
`;

// Centered logo container
const LogoContainer = styled(Box)`
  display: flex;
  justify-content: center;
`;

// Message container for the top text
const MessageContainer = styled(Box)`
  text-align: center;
  margin-bottom: 20px;
`;

// Navigation links row
const FooterNavigation = styled(Box)`
  display: flex;
  justify-content: center;
  margin: 40px 0 40px;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
`;

// Modified FooterLink with animation
const AnimatedFooterLink = styled(FooterLink)`
  position: relative;
  cursor: pointer;
  padding: 5px 0;
  transition: color 0.3s ease, transform 0.3s ease;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ff8a00;
    transition: width 0.3s ease;
  }

  &:hover {
    color: #ff8a00;
    transform: translateY(-2px);
  }

  &:hover:after {
    width: 100%;
  }
`;

// Bottom section with copyright and scroll to top
const FooterBottom = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

// Scroll to top button
const ScrollToTop = styled(IconButton)`
  width: 40px;
  height: 40px;
  background-color: #8a56ff; /* Purple accent */
  color: white;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #1d3557;
    transform: translateY(-3px);
  }
`;

const Footer = () => {
  // Navigation items with corresponding section IDs
  const navItems = [
    { name: "Services", section: "popular-services" },
    { name: "About Us", section: "about-us" },
    { name: "HOME", section: "home" }
  ];
  
  const handleNavClick = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      // Add highlight effect
      element.classList.add('active-section');
      
      // Scroll to the element
      element.scrollIntoView({ 
        behavior: "smooth",
        block: "start"
      });
      
      // Remove highlight after animation completes
      setTimeout(() => {
        element.classList.remove('active-section');
      }, 1500);
    }
  };

  const scrollToTop = () => {
    // Add highlight effect to home section
    const homeSection = document.getElementById('home');
    if (homeSection) {
      homeSection.classList.add('active-section');
      setTimeout(() => {
        homeSection.classList.remove('active-section');
      }, 1500);
    }
    
    // Smooth scroll to top
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <MainFooter>
      <FooterContainer>
        <Container maxWidth="lg">
          <LogoContainer>
            <FooterLogo 
              src="assets/images/logo.png" 
              alt="Local Pro" 
              style={{ cursor: 'pointer' }}
              onClick={scrollToTop}
            />
          </LogoContainer>

          <MessageContainer>
            <Typography
              variant="body1"
              sx={{
                fontSize: "18px",
                textAlign: "center",
                maxWidth: "800px",
                margin: "0 auto",
                padding: "0 15px",
              }}
            >
              Connect with trusted local professionals for all your service
              needs. LocalPro brings quality expertise to your doorstep with
              verified specialists across multiple industries.
            </Typography>
          </MessageContainer>
          <FooterNavigation>
          <AnimatedFooterLink onClick={() => handleNavClick('contact-us')}>
              Home
            </AnimatedFooterLink>
            <AnimatedFooterLink onClick={() => handleNavClick('popular-services')}>
              Services
            </AnimatedFooterLink>
            <AnimatedFooterLink onClick={() => handleNavClick('about-us')}>
              About Us
            </AnimatedFooterLink>
          </FooterNavigation>

          <FooterBottom>
            <CopyrightText
              sx={{ color: "rgba(255, 255, 255, 0.7)", fontWeight: "normal" }}
            >
              © Copyright 2024 LocalPro. All rights reserved.
            </CopyrightText>

            <SocialIcons>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CircularIconButton>
                  <TwitterIcon />
                </CircularIconButton>
              </a>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CircularIconButton>
                  <FacebookIcon />
                </CircularIconButton>
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CircularIconButton>
                  <InstagramIcon />
                </CircularIconButton>
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CircularIconButton>
                  <LinkedInIcon />
                </CircularIconButton>
              </a>
            </SocialIcons>

            {/* <ScrollToTop onClick={scrollToTop} aria-label="Scroll to top">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="18 15 12 9 6 15"></polyline>
              </svg>
            </ScrollToTop> */}
          </FooterBottom>
        </Container>
      </FooterContainer>
    </MainFooter>
  );
};

export default Footer;