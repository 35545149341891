import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Card,
  CardContent,
} from '@mui/material';
import { getMostReadPosts } from '../../services/api_calls/auth/AuthApi';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiTableCell-root': {
    padding: theme.spacing(1),
    fontSize: '0.875rem',
  },
  '& .MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  '& .MuiTableRow-root:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: theme.palette.action.selected,
    cursor: 'pointer',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .MuiCardContent-root': {
    padding: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)} !important`,
    flexGrow: 1, // This ensures the content takes up all available space
    display: 'flex',
    flexDirection: 'column',
  },
}));

const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

const MostReadPosts = ({ onPostClick }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMostReadPosts = async () => {
      try {
        setLoading(true);
        const response = await getMostReadPosts();
        if (response.success) {
          setPosts(response.posts);
        }
      } catch (error) {
        console.error('Error fetching most read posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMostReadPosts();
  }, []);

  if (loading) {
    return (
      <StyledCard elevation={3}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Most Read Posts
          </Typography>
          <Box sx={{ p: 2, textAlign: 'center' }}>Loading...</Box>
        </CardContent>
      </StyledCard>
    );
  }

  return (
    <StyledCard elevation={3}>
      <CardContent>
        <Typography variant="h6" sx={{ 
          borderBottom: '2px solid',
          borderColor: 'primary.main',
          pb: 1,
          mb: 2,
          fontWeight: 600
        }}>
          Most Read Posts
        </Typography>
        <StyledTableContainer sx={{ flexGrow: 1 }}>
          <Table size="small">
            <TableHead>
            </TableHead>
            <TableBody>
              {posts.slice(0, 5).map((post) => (
                <TableRow
                  key={post._id}
                  onClick={() => onPostClick(post)}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {post.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                        {formatDate(post.createdAt)} | {post.createdBy?.name || 'Admin'}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </CardContent>
    </StyledCard>
  );
};

export default MostReadPosts;