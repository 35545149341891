import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../../components/UI/Header";
import LocationSearchInput from "../../../components/UI/Input/LocationSearchInput";
import SelectInput from "../../../components/UI/Select/SelectInput";
import SearchIcon from "@mui/icons-material/Search";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { StyledFormButton } from "../../../components/UI/Button/StyledButtons";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
// import { getCityData } from "../../../redux/auth/AuthSlice";
import { COLORS } from "../../../styles/Theme";

function ChooseLocation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { cityContent, cityContentLoader } = useSelector((state) => state.auth);

  const [location, setLocation] = useState("Bangalore");
  const [locationSearch, setLocationSearch] = useState("");

  const [zipCode, setZipCode] = useState("");

  const [addressError, setAddressError] = useState("");
  const [btnDisable, setbtnDisable] = useState(true);

  // useEffect(() => {
  //   if (!cityContent) {
  //     dispatch(getCityData());
  //   }
  //   return () => {};
  // }, [cityContent]);

  useEffect(() => {
    if (cityContent) {
      const google = window.google;
      const maps = google.maps;

      let input = document.getElementById("search-location");
      let autocomplete = new maps.places.Autocomplete(input);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        for (let i = 0; i < place.address_components.length; i++) { 
          for (let j = 0; j < place.address_components[i].types.length; j++) {
            if (place.address_components[i].types[j] == "postal_code") {
              setZipCode(place.address_components[i].long_name);
              validateZipCode(place.address_components[i].long_name);
            }
          }
        }
        setLocationSearch(place?.formatted_address);
        console.log("places:", place);
      });
    }

    return () => {};
  }, [cityContent]);

  const validateZipCode = (selectedZip) => {
    let zipCodeList = [];

    cityContent?.data?.forEach((element) => {
      zipCodeList = [...zipCodeList, ...element?.zones];
    });

    // console.log("API_ZONES", zipCodeList);
    // console.log("IsZipPresent", zipCodeList.includes("577201"));

    const isZipPresent = zipCodeList.includes(selectedZip);

    if (isZipPresent) {
      localStorage.setItem("user_address", locationSearch);
      localStorage.setItem("user_zipcode", selectedZip);
      setAddressError("");
      setbtnDisable(false);
    } else {
      setAddressError("Selected Address Not Avaiable");
      setbtnDisable(true);
    }
  };

  return (
    <Box>
      <Header title="Choose Your Location" />

      <FormStyled>
        {cityContent ? (
          <SelectInput // console.log("API_ZONES", zipCodeList);
            // console.log("IsZipPresent", zipCodeList.includes("577201"));
            value={location}
            onValueChange={(text) => {
              setLocation(text);
              setbtnDisable(false);
            }}
            menuList={cityContent.data}
          />
        ) : null}

        <LocationSearchInput
          inputId="search-location"
          value={locationSearch}
          onChange={({ target }) => {
            setLocationSearch(target.value);
          }}
          placeholder="Search Your Location"
          startIcon={<SearchIcon />}
          endIcon={<MyLocationIcon />}
        />

        <Typography variant="body2" fontSize={12} color={COLORS.red}>
          {addressError}
        </Typography>

        <br />
        <div>Address: {locationSearch}</div>
        <div>ZIP CODE: {zipCode}</div>

        <StyledFormButton
          onClick={() => {
            navigate("/otpVerify");
          }}
          disabled={btnDisable}
        >
          Apply
        </StyledFormButton>
      </FormStyled>
    </Box>
  );
}

export default ChooseLocation;

const FormStyled = styled.form({
  padding: "25px",
});
