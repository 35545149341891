import { Box, IconButton, Typography, Drawer, List, ListItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import { HeaderContainer } from "./DownloadStyle";
import MenuIcon from '@mui/icons-material/Menu';

function DashboardHeader() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("HOME");
  
  // Navigation items with corresponding section IDs
  const navItems = [
    { name: "HOME", section: "home" },
    { name: "SERVICES", section: "popular-services" },
    { name: "ABOUT US", section: "about-us" },
  ];

  const handleNavClick = (item) => {
    // Update active menu item
    setActiveItem(item.name);
    
    // Close drawer if open (mobile view)
    setDrawerOpen(false);
    
    // Scroll to the section
    const element = document.getElementById(item.section);
    if (element) {
      // Add a small animation to highlight the section being navigated to
      element.classList.add('active-section');
      
      // Scroll to the element
      element.scrollIntoView({ 
        behavior: "smooth",
        block: "start"
      });
      
      // Remove the highlight class after animation completes
      setTimeout(() => {
        element.classList.remove('active-section');
      }, 1500);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  // Handle scroll events to update active menu item
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 100; // Add offset for header
      
      // Check which section is currently in view
      for (let i = navItems.length - 1; i >= 0; i--) {
        const section = document.getElementById(navItems[i].section);
        if (section) {
          const offset = section.offsetTop;
          const height = section.offsetHeight;
          
          if (scrollPosition >= offset && scrollPosition < offset + height) {
            setActiveItem(navItems[i].name);
            break;
          }
        }
      }
    };
    
    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);
    
    // Initial check on mount
    handleScroll();
    
    // Clean up event listener
    return () => window.removeEventListener('scroll', handleScroll);
  }, [navItems]);

  return (
    <HeaderContainer>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Box
          component="img"
          src="/./assets/images/logo.jpg"
          alt="Local Pro"
          width={122}
          height={58}
          sx={{ 
            objectFit: "contain",
            cursor: "pointer",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)"
            }
          }}
          onClick={() => handleNavClick(navItems[0])}
        />
        
        {/* Mobile menu button */}
        <IconButton 
          edge="end" 
          color="inherit" 
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        {/* Desktop navigation */}
        <Box 
          display="flex" 
          alignItems="center"
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          {navItems.map((item, index) => (
            <Typography 
              key={index}
              onClick={() => handleNavClick(item)}
              sx={{
                mx: 2,
                fontWeight: 500,
                fontSize: "14px",
                cursor: "pointer",
                color: activeItem === item.name ? "#ff8a00" : "#002e5b",
                position: "relative",
                padding: "5px 0",
                transition: "all 0.3s ease",
                "&:hover": {
                  color: "#ff8a00",
                  transform: "translateY(-2px)"
                },
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: "-2px",
                  left: "0",
                  width: activeItem === item.name ? "100%" : "0%",
                  height: "2px",
                  backgroundColor: "#ff8a00",
                  transition: "all 0.3s ease"
                },
                "&:hover::after": {
                  width: "100%"
                }
              }}
            >
              {item.name}
            </Typography>
          ))}
        </Box>
      </Box>

      {/* Mobile navigation drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ 
            width: 250,
            backgroundColor: "#002139",
            height: "100%",
            color: "white"
          }}
          role="presentation"
        >
          <List>
            {navItems.map((item, index) => (
              <ListItem 
                key={index}
                onClick={() => handleNavClick(item)}
                sx={{
                  borderBottom: "1px solid rgba(255,255,255,0.1)",
                  py: 2,
                  backgroundColor: activeItem === item.name ? "rgba(255,138,0,0.2)" : "transparent",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: "rgba(255,138,0,0.15)"
                  }
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "16px",
                    cursor: "pointer",
                    width: "100%",
                    color: activeItem === item.name ? "#ff8a00" : "white",
                    transition: "color 0.3s ease"
                  }}
                >
                  {item.name}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </HeaderContainer>
  );
}

export default DashboardHeader;