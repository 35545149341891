// import { Box, IconButton } from "@mui/material";
// import React, { useRef, useState } from "react";
// import { COLORS } from "../../../styles/Theme";
// import EditIcon from "@mui/icons-material/Edit";
// import styled from "@emotion/styled";
// import { getS3Url } from "../../../services/api_calls/profile/profileApi";
// import { registerUserApi } from '../../../services/api_calls/auth/AuthApi';

// const ImgContainer = styled(Box)`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   margin: 20px 0;
// `;

// const ProfileImg = styled.img`
//   width: 100px;
//   height: 100px;
//   border-radius: 50%;
//   object-fit: cover;
//   border: 2px solid ${COLORS.white};
// `;

// const StyledIconButton = styled(IconButton)`
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   background-color: ${COLORS.white};
//   padding: 5px;
  
//   &:hover {
//     background-color: ${COLORS.white};
//   }
// `;

// function ProfileImgWithEdit({ image, setImage, userData, onUpdateComplete }) {
//   const inputRef = useRef(null);
//   const [uploading, setUploading] = useState(false);

//   const triggerFile = () => inputRef.current.click();

//   const handleImageUpload = async (file) => {
//     try {
//       // Upload to S3
//       const s3Response = await getS3Url({
//         img: file
//       });

//       if (!s3Response || !s3Response.data) {
//         throw new Error('Failed to upload image');
//       }

//       // Keep all original user data and only update profilePicture
//       const updateResponse = await registerUserApi({
//         name: userData.name,
//         email: userData.email,
//         mobile: userData.mobile,
//         whatsApp: userData.whatsApp || "", 
//         gender: userData.gender,
//         profilePicture: s3Response.data  // Only updating this field
//       });

//       if (!updateResponse || !updateResponse.user) {
//         throw new Error('Failed to update profile picture');
//       }

//       return s3Response.data;
//     } catch (error) {
//       console.error('Error in image upload process:', error);
//       throw error;
//     }
//   };

//   const onSelectFile = async (event) => {
//     if (event.target.files && event.target.files.length > 0) {
//       const file = event.target.files[0];
//       const originalImage = image;

//       try {
//         setUploading(true);

//         const reader = new FileReader();
//         reader.onload = (e) => {
//           setImage(e.target.result);
//         };
//         reader.readAsDataURL(file);

//         const newImageUrl = await handleImageUpload(file);
//         if (newImageUrl) {
//           setImage(newImageUrl);
//           if (onUpdateComplete) {
//             onUpdateComplete();
//           }
//         } else {
//           throw new Error('No image URL returned');
//         }
//       } catch (error) {
//         console.error('Failed to update profile picture:', error);
//         setImage(originalImage);
//         alert('Failed to update profile picture. Please try again.');
//       } finally {
//         setUploading(false);
//       }
//     }
//   };

//   return (
//     <>
//       <input
//         type="file"
//         accept="image/*"
//         ref={inputRef}
//         onChange={onSelectFile}
//         style={{ display: "none" }}
//       />

//       <ImgContainer>
//         <Box position="relative">
//           <ProfileImg
//             src={image}
//             alt="Profile"
//             style={{ opacity: uploading ? 0.7 : 1 }}
//           />

//           <StyledIconButton 
//             size="small" 
//             onClick={triggerFile}
//             disabled={uploading}
//           >
//             <EditIcon sx={{ fontSize: 16 }} />
//           </StyledIconButton>
//         </Box>
//       </ImgContainer>
//     </>
//   );
// }

// export default ProfileImgWithEdit;









import { Box, IconButton } from "@mui/material";
import React from "react";
import { COLORS } from "../../../styles/Theme";
import EditIcon from "@mui/icons-material/Edit";
import styled from "@emotion/styled";

const ImgContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const ProfileImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid ${COLORS.white};
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: ${COLORS.white};
  padding: 5px;
  
  &:hover {
    background-color: ${COLORS.white};
  }
`;

function ProfileImgWithEdit({ image, onEdit, editable }) {
  return (
    <ImgContainer>
      <Box position="relative">
        <ProfileImg src={image} alt="Profile" />
        {editable && (
          <StyledIconButton size="small" onClick={onEdit}>
            <EditIcon sx={{ fontSize: 16 }} />
          </StyledIconButton>
        )}
      </Box>
    </ImgContainer>
  );
}

export default ProfileImgWithEdit;