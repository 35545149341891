import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const MainFooter = styled(Box)`
  position: relative;
  z-index: 5;
`;

export const FooterContainer = styled(Box)`
  background-color: #1d3557;
  padding: 40px 0px;
  color: #ffffff;
`;

export const FooterLogo = styled('img')`
  width: 180px;
  margin-bottom: 40px;
`;

export const SocialIcons = styled(Box)`
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

export const FooterLink = styled(Typography)`
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #8a56ff;
  }
`;

// Footer contact text
export const FooterContact = styled(Typography)`
  color: #ffffff;
  margin-bottom: 5px;
  font-size: 16px;
`;

export const CopyrightText = styled(Typography)`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: normal;
  
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const FooterText = styled(Typography)`
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 2.5;
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: color 0.3s ease;

  &:hover {
    color: #8a56ff;
  }
`;

export const FooterText1 = styled(Typography)`
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  line-height: 2.5;
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
`;

// Message container for the top text
export const MessageContainer = styled(Box)`
  text-align: center;
  margin-bottom: 50px;
`;

export const MessageText = styled(Typography)`
  font-size: 18px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.9);
  max-width: 800px;
  margin: 0 auto;
`;

// Navigation links row
export const FooterNavigation = styled(Box)`
  display: flex;
  justify-content: center;
  margin: 30px 0 50px;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
`;

// Bottom section with copyright and social icons
export const FooterBottom = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 30px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

// Scroll to top button
export const ScrollToTop = styled(Box)`
  width: 40px;
  height: 40px;
  background-color: #8a56ff;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #7442e6;
  }
`;

// Styled for circular social icon buttons
export const CircularIconButton = styled(Box)`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  svg {
    color: #1d3557;
    font-size: 20px;
  }
`;