import styled from "@emotion/styled";
import { Box, IconButton, Typography, Button } from "@mui/material";
// import ShareIcon from "@mui/icons-material/Share";
import React, { useState } from "react";
import { textOneLine } from "../../styles/Global";
import { COLORS } from "../../styles/Theme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import NotVerifiedIcon from "@mui/icons-material/Cancel";
import NotVerifiedIcon from "@mui/icons-material/Block";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
// import StarIcon from "@mui/icons-material/Star";
// import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import DirectionsBikeRoundedIcon from "@mui/icons-material/DirectionsBikeRounded";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
// import EnquiryModal from "../../pages/ProfessionalDetails/EnquiryModal";
// import CallNowModal from "../../pages/ProfessionalDetails/CallNowModal";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
// import {SERVICE_TEXT } from "../../utils/Data";
// import { serviceSliderSettings } from "../../utils/SlickConfigs";
// import Slider from "react-slick";
function ProfessionalInfoCard({
  imgUrl,
  name,
  title,
  rating,
  reviewCount,
  caterogy,
  timings,
  services,
  address,
  distance,
  isVerified,
  username,
  userRating,
  isRatingInline,
  showLastContacted,
  showReviewContent,
  isNotAvailable,
  onContantClick,
  spacing,
  cardWidth,
  phoneNumber,
  businessName,
  vendor_id,
  service_id,
  isSubscribed,
  addToWishlistApi,
  getWishlistApi,
  isWishlisted: initialWishlistState,
  onWishlistUpdate,
  createCallApi,
}) {
  // const [isEnquiryModalOpen, setIsEnquiryModalOpen] = useState(false);
  // const [isCallModalOpen, setIsCallModalOpen] = useState(false);
  // const handleOpenEnquiryModal = () => {
  //   setIsEnquiryModalOpen(true);
  // };

  // const handleCloseEnquiryModal = () => {
  //   setIsEnquiryModalOpen(false);
  // };
  // const handleOpenCallModal = () => {
  //   setIsCallModalOpen(true);
  // };

  // const handleCloseCallModal = () => {
  //   setIsCallModalOpen(false);
  // };
  const [isWishlisted, setIsWishlisted] = useState(initialWishlistState);
  const [isCallLoading, setIsCallLoading] = useState(false);
  const handleWishlist = async (e) => {
    e.stopPropagation();
    try {
      const response = await addToWishlistApi({
        vendor_id: vendor_id,
        service_id: service_id,
      });
      if (response.status) {
        setIsWishlisted(!isWishlisted);
      }
      if (onWishlistUpdate) {
        await onWishlistUpdate();
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
    }
  };
  const handleCall = async (e) => {
    e.stopPropagation();
    if (isCallLoading) return;
  
    setIsCallLoading(true);
    try {
      const response = await createCallApi({
        vendor_id: vendor_id,
        service_id: service_id,
      });

      if (response?.success) {
        window.location.href = `tel:${phoneNumber}`;
      }
    } catch (error) {
      console.error("Error making call:", error);
    } finally {
      setIsCallLoading(false);
    }
  };
  return (
    <>
      <StyledCard cardWidth={cardWidth} onClick={onContantClick} m={spacing}>
        <CardContent>
          <StyledProfileBox>
            <Box
              component="img"
              src={imgUrl || "/assets/images/home-banner.png"}
              alt="professional img"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/images/home-banner.png";
              }}
            />
          </StyledProfileBox>

          <Box flex={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap="8px"
            >
              <Box display="flex" alignItems="center" gap="5px">
                <Typography
                  variant="boby1"
                  fontSize={13}
                  fontWeight={500}
                  color={COLORS.black}
                  sx={{ ...textOneLine }}
                >
                  {title}
                </Typography>
              </Box>
              {isVerified ? (
                <VerifiedBadge>
                  <CheckCircleIcon sx={{ fontSize: 18, color: "green" }} />
                  <Typography
                    variant="body2"
                    fontSize={10}
                    fontWeight={600}
                    sx={{ color: "green", ml: 0.5 }}
                  >
                    Verified
                  </Typography>
                </VerifiedBadge>
              ) : (
                <NotVerifiedBadge>
                  <NotVerifiedIcon sx={{ fontSize: 14, color: "red" }} />
                  <Typography
                    variant="body2"
                    fontSize={10}
                    fontWeight={600}
                    sx={{ color: "red", ml: 0.5 }}
                  >
                    Not Verified
                  </Typography>
                </NotVerifiedBadge>
              )}
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap="3px"
              mr={0.5}
            >
              <Box>
                {isRatingInline && (
                  <Box mb="-3px">
                    <Typography
                      variant="body1"
                      fontSize={11}
                      fontWeight={600}
                      color={COLORS.black}
                      display="inline"
                    >
                      <StarRoundedIcon
                        sx={{
                          fontSize: 16,
                          color: COLORS.secondaryColor,
                          m: "0 3px -3px 0",
                        }}
                      />
                      {rating}
                    </Typography>

                    <Typography
                      variant="boby1"
                      fontSize={11}
                      fontWeight={500}
                      color={COLORS.blackShade1}
                      display="inline"
                      ml={1}
                    >
                      {reviewCount} Reviews
                    </Typography>
                  </Box>
                )}
                {!isRatingInline && (
                  <Typography
                    variant="boby1"
                    fontSize={11}
                    fontWeight={600}
                    color={COLORS.black}
                    display="inline"
                    mr={0.3}
                  >
                    <StarRoundedIcon
                      sx={{
                        fontSize: 16,
                        color: COLORS.secondaryColor,
                        m: "0 3px -3px 0",
                      }}
                    />
                    {rating}
                    <Typography
                      variant="boby1"
                      fontSize={11}
                      fontWeight={500}
                      color={COLORS.blackShade1}
                      display="inline"
                      ml={1}
                    >
                      ({reviewCount} Reviews)
                    </Typography>
                  </Typography>
                )}
                <Typography
                  variant="boby1"
                  fontSize={11}
                  fontWeight={500}
                  color={COLORS.blackShade1}
                  display="inline"
                  sx={{ ...textOneLine, m: "4px 0 0" }}
                >
                  <TimerOutlinedIcon
                    sx={{
                      fontSize: 14,
                      color: COLORS.blackShade1,
                      m: "0 3px -4px 0",
                    }}
                  />
                  {timings}
                </Typography>

                <Typography
                  variant="boby1"
                  fontSize={11}
                  fontWeight={500}
                  color={COLORS.blackShade1}
                  display="inline"
                  sx={{ ...textOneLine, m: "4px 0" }}
                >
                  <LocationOnIcon
                    sx={{
                      fontSize: 14,
                      color: COLORS.primaryColor,
                      m: "0 3px -4px 0",
                    }}
                  />
                  {address}
                </Typography>

                {distance && (
                  <Typography
                    variant="boby1"
                    fontSize={11}
                    fontWeight={600}
                    color={COLORS.primaryColor}
                    display="inline"
                    sx={{ ...textOneLine, m: "4px 0" }}
                  >
                    <DirectionsBikeRoundedIcon
                      sx={{
                        fontSize: 16,
                        color: COLORS.blackShade1,
                        m: "0 3px -4px 0",
                      }}
                    />
                    {distance}
                  </Typography>
                )}
              </Box>
              {/* Wishlist and Share Icons */}
              {isVerified && (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                  justifyContent="center"
                  gap="8px"
                  position="relative"
                  sx={{
                    marginTop: "0px", // Adjust if needed
                    marginRight: "10px", // Adjust for proper spacing from right
                  }}
                >
                  <WishlistButton size="small" onClick={handleWishlist}>
                    {isWishlisted ? (
                      <FavoriteRoundedIcon
                        sx={{ fontSize: 14, color: "red" }}
                      />
                    ) : (
                      <FavoriteBorderRoundedIcon sx={{ fontSize: 14 }} />
                    )}
                  </WishlistButton>
                  {/* <ShareButton size="small">
                    <ShareIcon sx={{ fontSize: 14 }} />
                  </ShareButton> */}
                </Box>
              )}
            </Box>
          </Box>
        </CardContent>

        {showLastContacted && (
          <LastConnectedBox>
            <Typography
              variant="boby1"
              fontSize={10}
              fontWeight={500}
              color={COLORS.black}
            >
              Last Contacted :{" "}
              <Typography variant="boby1" fontWeight={400}>
                Dec 12, 2022 at 7:05pm
              </Typography>
            </Typography>
          </LastConnectedBox>
        )}
        <Box
          sx={{
            display: "flex",
            overflowX: "scroll",
            whiteSpace: "nowrap",
            gap: "10px",
            padding: "10px 0",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {Array.isArray(services) ? (
            services.map((service, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: "#ECECEC",
                  padding: "5px 15px",
                  borderRadius: "9px",
                  fontSize: "12px",
                  color: "#656565",
                  cursor: "pointer",
                  display: "inline-block",
                  flexShrink: 0,
                }}
              >
                {service}
              </Box>
            ))
          ) : (
            <Box
              sx={{
                backgroundColor: "#ECECEC",
                padding: "5px 15px",
                borderRadius: "9px",
                fontSize: "12px",
                color: "#656565",
                cursor: "pointer",
                display: "inline-block",
                flexShrink: 0,
              }}
            >
              {services}
            </Box>
          )}
        </Box>
        {isVerified && (
          <Box sx={{ borderTop: "0.3px dashed " + COLORS.greyShade1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              {/* Premium icon container - always on left */}
              <Box sx={{ flex: "0 0 auto" }}>
                {isSubscribed && (
                  <Button>
                    <Box
                      component="img"
                      src="/assets/images/Group 5261.svg"
                      alt="Premium Icon"
                      sx={{
                        width: {
                          xs: "120px",
                          sm: "100px",
                          md: "130px",
                        },
                        height: "auto",
                      }}
                    />
                  </Button>
                )}
              </Box>

              {/* Button container - always on right */}
              <Box
                sx={{
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {/* <Button
                  variant="outlined"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  sx={{
                    borderColor: "#21608F",
                    color: "#21608F",
                    borderRadius: "10px",
                    padding: "8px 16px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    minWidth: "auto",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    "@media (max-width: 600px)": {
                      padding: "6px 12px",
                      fontSize: "12px",
                    },
                  }}
                >
                  <ShareIcon sx={{ fontSize: 16 }} />
                  Share
                </Button> */}

                <Button
                  variant="contained"
                  onClick={handleCall}
                  disabled={isCallLoading}
                  sx={{
                    backgroundColor: "#21608F",
                    color: "#fff",
                    borderRadius: "10px",
                    padding: "8px 16px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor: "#21608F",
                    },
                    "@media (max-width: 600px)": {
                      padding: "6px",
                      fontSize: "12px",
                    },
                    opacity: isCallLoading ? 0.7 : 1,
                  }}
                >
                  {isCallLoading ? "Processing..." : "Call Now"}
                  {!isCallLoading && (
                    <img
                      src="/assets/images/Icon ionic-ios-call.svg"
                      alt="Phone Icon"
                      style={{ marginLeft: "8px", width: "16px" }}
                    />
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </StyledCard>
      {/* <EnquiryModal
    open={isEnquiryModalOpen}
    handleClose={handleCloseEnquiryModal}
    vendorId={vendor_id}
    serviceId={service_id}
    businessName={businessName}
/> */}
      {/* <CallNowModal
        open={isCallModalOpen}
        handleClose={handleCloseCallModal}
        businessName={businessName}
        phoneNumber={phoneNumber}
      /> */}
    </>
  );
}

export default ProfessionalInfoCard;

// const CallButton = ({ onClick, disabled }) => (
//   <StyledCallButton onClick={onClick} disabled btndisabled={disabled}>
//     <StylesIcon>
//       <PhoneRoundedIcon fontSize="small" />
//     </StylesIcon>

//     <Typography
//       variant="h4"
//       fontSize={10}
//       fontWeight={500}
//       color="primary"
//       textAlign="center"
//       mt={1}
//     >
//       Contact
//     </Typography>
//   </StyledCallButton>
// );

const StyledCard = styled(Box)(({ cardWidth }) => ({
  width: cardWidth || "auto",
  backgroundColor: COLORS.white,
  borderRadius: "8px",
  boxShadow: "0px 1px 3px 1px rgba(0,0,0,0.13)",
  cursor: "pointer",
  padding: "10px",
}));

const CardContent = styled(Box)({
  display: "flex",
  gap: "10px",
  padding: "5px",
});

const StyledProfileBox = styled(Box)({
  width: 110,
  height: 110,
  borderRadius: "6px",
  overflow: "hidden",
  boxShadow: "0px 1px 3px 1px rgba(0,0,0,0.13)",
  position: "relative",
});

export const StylesIcon = styled(Box)({
  width: 29,
  height: 29,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: COLORS.primaryColor,
  backgroundColor: COLORS.white,
  border: "1px solid " + COLORS.primaryColor,
  borderRadius: 25,
});

export const LastConnectedBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "6px 15px",
  marginTop: "3px",
  borderTop: "0.3px dashed " + COLORS.greyShade1,
});

export const ReviewContentBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  padding: "6px 15px",
  marginTop: "3px",
  borderTop: "0.3px dashed " + COLORS.greyShade1,
});

export const StyledCallButton = styled.button(({ btndisabled }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: COLORS.white,
  opacity: btndisabled ? 0.2 : 1,
  border: "none",
  cursor: "pointer",
}));

export const WishlistButton = styled(IconButton)({
  width: 30,
  height: 30,
  color: COLORS.secondaryColor,
  backgroundColor: COLORS.white,
  // position: "absolute",
  // top: 5,
  // right: 50,
  boxShadow: "0px 1px 3px 1px rgba(0,0,0,0.13)",
});
export const ShareButton = styled(IconButton)({
  width: 30,
  height: 30,
  color: COLORS.primaryColor,
  backgroundColor: COLORS.white,
  boxShadow: "0px 1px 3px 1px rgba(0,0,0,0.13)",
});
const VerifiedBadge = styled(Box)({
  // position: "absolute",
  // top: "5px",
  // right: "5px",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#E6FFED",
  padding: "2px 6px",
  borderRadius: "5px",
});

const NotVerifiedBadge = styled(Box)({
  // position: "absolute",
  // top: "5px",
  // right: "5px",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#FFE6E6",
  padding: "2px 6px",
  borderRadius: "5px",
});
