// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { getCityDataApi } from "../../services/api_calls/auth/AuthApi";

// const initialState = {
//   cityContent: null,
// //   homeCategary: null,

//   // loader
//   cityContentLoader: false,
// //   homeCategaryLooader: false,
// };

// export const getCityData = createAsyncThunk(
//   "getCityData",
//   async (thunkAPI) => {
//     const responseData = await getCityDataApi();
//     return responseData;
//   }
// );

// // export const getHomeCategoryData = createAsyncThunk(
// //   "getHomeCategoryData",
// //   async (thunkAPI) => {
// //     const responseData = await getTestDataApi();
// //     return responseData;
// //   }
// // );

// export const authSlice = createSlice({
//   name: "auth",
//   initialState,
//   reducers: {},
//   extraReducers: {
//     // just for testing
//     [getCityData.pending]: (state) => {
//       state.cityContentLoader = true;
//     },
//     [getCityData.fulfilled]: (state, { payload }) => {
//       state.cityContentLoader = false;
//       state.cityContent = payload;
//     },
//     [getCityData.rejected]: (state) => {
//       state.cityContentLoader = false;
//     },

//     // // category data
//     // [getHomeCategoryData.pending]: (state) => {
//     //   state.homeCategaryLooader = true;
//     // },
//     // [getHomeCategoryData.fulfilled]: (state, { payload }) => {
//     //   state.homeCategaryLooader = false;
//     //   state.homeCategary = payload;
//     // },
//     // [getHomeCategoryData.rejected]: (state) => {
//     //   state.homeCategaryLooader = false;
//     // },
//   },
// });

// export const authReducer = authSlice.reducer;

// src/redux/slices/authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRegisterApi, userVerifyOtp, registerUserApi } from "../../services/api_calls/auth/AuthApi";

const initialState = {
  isLoggedIn: !!localStorage.getItem('token'), // Check if token exists on load
  userData: null,
  loading: false,
  error: null,
  otpVerified: false,
  registrationComplete: false
};

export const fetchUserProfile = createAsyncThunk(
  'auth/fetchProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRegisterApi();
      if (response?.user) {
        return response.user;
      }
      return rejectWithValue('No user data found');
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const verifyOTP = createAsyncThunk(
  'auth/verifyOTP',
  async ({ mobilenumber, otp }, { rejectWithValue }) => {
    try {
      const response = await userVerifyOtp({ mobilenumber, otp });
      if (response?.status === true && response?.access_token) {
        // Store token but don't set isLoggedIn yet
        localStorage.setItem('token', response.access_token);
        return response;
      }
      return rejectWithValue(response?.message || 'Verification failed');
    } catch (error) {
      return rejectWithValue(error.message || 'Verification failed');
    }
  }
);

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async (userData, { rejectWithValue, dispatch }) => {
    try {
      const response = await registerUserApi(userData);
      if (response?.status) {
        // After successful registration, fetch profile and complete login
        await dispatch(fetchUserProfile()).unwrap();
        return response;
      }
      return rejectWithValue(response?.message || 'Registration failed');
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.userData = null;
      state.otpVerified = false;
      localStorage.removeItem('token');
      localStorage.removeItem('authToken');
      localStorage.removeItem('userMobileNumber');
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    completeRegistration: (state) => {
      state.registrationComplete = true;
      state.isLoggedIn = true; // Only set login after registration is complete
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload;
        if (state.registrationComplete) {
          state.isLoggedIn = true;
        }
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(verifyOTP.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.otpVerified = true;
        if (action.payload.user) {
          state.userData = action.payload.user;
          if (action.payload.user.is_registered) {
            state.registrationComplete = true;
            state.isLoggedIn = true; // Only login if user is already registered
          }
        }
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.registrationComplete = true;
        state.isLoggedIn = true;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { logout, setError, clearError,completeRegistration  } = authSlice.actions;
export default authSlice.reducer;