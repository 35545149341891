import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Checkbox,
  Typography,
  Box,
} from '@mui/material';
import OTPDialog from './OTPModal';
import RegistrationDialog from './RegisterModal';
import { userSentOtp } from '../../../services/api_calls/auth/AuthApi';
import { useDispatch } from 'react-redux';
import { fetchUserProfile, verifyOTP } from '../../../redux/auth/AuthSlice';

const LoginDialog = ({ open, onClose,onLoginSuccess }) => {
  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [registrationOpen, setRegistrationOpen] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setCheckboxError("");
    }
  };

  const handleInputChange = (event) => {
    const input = event.target.value;
    if (/^\d{0,10}$/.test(input)) {
      setMobileNumber(input);
      setError("");
    }
  };

  const handleLoginClick = async () => {
    if (mobileNumber.length !== 10) {
      setError("Please enter a valid 10-digit mobile number");
    } else if (!isChecked) {
      setCheckboxError("Please agree to the Terms and Conditions");
    } else {
      setError("");
      setCheckboxError("");

      try {
        const response = await userSentOtp({ mobileNumber });

        if (response.status) {
          setOtpModalOpen(true);
        } else {
          setError(response.message || "Failed to send OTP.");
        }
      } catch (error) {
        setError("Failed to send OTP. Please try again.");
      }
    }
  };

  const handleCloseOtpModal = () => {
    setOtpModalOpen(false);
  };

  const handleEditPhone = () => {
    setOtpModalOpen(false);
  };

  const handleBack = () => {
    setOtpModalOpen(false);
  };

  // const handleOtpSuccess = (userData) => {
  //   // console.log("OTP Success Data:", userData);
    
  //   if (userData?.access_token) {
  //     localStorage.setItem('authToken', userData.access_token);
      
  //     if (userData?.user?.is_registered) {
  //       setOtpModalOpen(false);
  //       setRegistrationOpen(false);
  //       onLoginSuccess(userData.access_token);
  //       onClose();
  //     } else {
  //       setOtpModalOpen(false);
  //       setRegistrationOpen(true);
  //     }
  //   } else {
  //     console.error("No access token received");
  //   }
  // };
  const handleOtpSuccess = async (userData) => {
    if (userData?.access_token) {
      localStorage.setItem('token', userData.access_token);
      localStorage.setItem('userMobileNumber', mobileNumber);
      
      try {
        if (userData.isRegistered) {
          // If user is already registered, fetch profile and complete login
          await dispatch(fetchUserProfile()).unwrap();
          setOtpModalOpen(false);
          setRegistrationOpen(false);
          onClose();
        } else {
          // If user is not registered, show registration dialog
          setOtpModalOpen(false);
          setRegistrationOpen(true);
        }
      } catch (error) {
        console.error("Auth error:", error);
        setError(error.message || "Authentication failed");
      }
    } else {
      setError("No access token received");
    }
  };
  // const handleRegistrationClose = () => {
  //   const token = localStorage.getItem('authToken');
  //   setRegistrationOpen(false);
  //   onClose();
  //   if (token) {
  //     onLoginSuccess(token);
  //   }
  // };
  const handleRegistrationClose = async () => {
    try {
      // After registration is complete, fetch the profile one final time
      await dispatch(fetchUserProfile()).unwrap();
      setRegistrationOpen(false);
      onClose();
    } catch (error) {
      console.error("Failed to fetch profile:", error);
      setError("Failed to complete login. Please try again.");
    }
  };
  return (
    <>
      {/* Login Dialog */}
      <Dialog open={open && !otpModalOpen && !registrationOpen} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <img src="./assets/images/logo.jpg" alt="Logo" style={{ marginRight: 8, width: '100px' }} />
          <Typography variant="h6">Welcome</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            Enter OTP To Continue
          </Typography>

          <Box display="flex" alignItems="center" gap={1} my={2}>
            <Typography variant="body2">+91</Typography>
            <TextField
              variant="outlined"
              placeholder="Enter Mobile Number*"
              fullWidth
              size="small"
              value={mobileNumber}
              onChange={handleInputChange}
              error={Boolean(error)}
              helperText={error}
            />
          </Box>

          <Box display="flex" alignItems="center" mb={1}>
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              color="primary"
            />
            <Typography variant="body2">
              I Agree to <a href="#">Terms and Conditions</a> and{' '}
              <a href="#">Privacy Policy</a>
            </Typography>
          </Box>
          {checkboxError && (
            <Typography color="error" variant="caption" display="block" mb={2}>
              {checkboxError}
            </Typography>
          )}

          <Button 
            variant="contained" 
            onClick={handleLoginClick}
            fullWidth
            sx={{
              mb: 2, // Margin bottom
              backgroundColor: '#226090',
              '&:hover': {
                backgroundColor: '#226090',
              },
            }}
          >
            Login / Sign Up
          </Button>

          <Box display="flex" justifyContent="center" mt={2}>
            <Button color="inherit" onClick={onClose}>Skip</Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* OTP Modal */}
      <OTPDialog
        open={otpModalOpen}
        onClose={handleCloseOtpModal}
        phoneNumber={mobileNumber}
        onEditPhone={handleEditPhone}
        onBack={handleBack}
        onSuccess={handleOtpSuccess}
      />

      {/* Registration Modal */}
      <RegistrationDialog
        open={registrationOpen}
        onClose={handleRegistrationClose}
        phoneNumber={mobileNumber}
      />
    </>
  );
};

export default LoginDialog;
