import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  Box,
  Button,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import { registerUserApi, getS3Url } from "../../../services/api_calls/auth/AuthApi";
import { useDispatch } from 'react-redux';
import { fetchUserProfile, registerUser } from "../../../redux/auth/AuthSlice";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: "120px",
  height: "120px",
  background: "#f5f5f5",
  cursor: "pointer",
  position: "relative",
  margin: "0 auto",
}));

const StyledEditIcon = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: "0px",
  top: "20%",
  transform: "translateY(-50%)",
  backgroundColor: "#FFA500",
  borderRadius: "50%",
  width: "28px",
  height: "28px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  zIndex: 1,
}));

const RegistrationDialog = ({ open, onClose, phoneNumber }) => {
  const dispatch = useDispatch();
  const initialFormData = {
    name: "",
    email: "",
    mobile: phoneNumber || "",
    whatsApp: false,
    gender: "Male",
    profilePicture: "photo",
    photo: null,
    photoPreview: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (phoneNumber) {
      setFormData((prev) => ({
        ...prev,
        mobile: phoneNumber,
      }));
    }
  }, [phoneNumber]);
  useEffect(() => {
    if (!open) {
      setFormData(initialFormData);
      setError("");
    }
  }, [open]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // File size validation (5MB)
      if (file.size > 5 * 1024 * 1024) {
        setError("File size should be less than 5MB");
        return;
      }

      // File type validation
      if (!file.type.startsWith('image/')) {
        setError("Please select an image file");
        return;
      }

      setFormData(prev => ({
        ...prev,
        photo: file,
        photoPreview: URL.createObjectURL(file)
      }));
      setError("");
    }
  };

  const uploadToS3 = async (file) => {
    try {
      if (!file) {
        throw new Error("No file provided");
      }

      console.log("Attempting to upload file:", {
        name: file.name,
        type: file.type,
        size: file.size
      });

      const response = await getS3Url({
        img: file
      });

      if (!response?.status || !response?.data) {
        console.error("Invalid S3 response:", response);
        throw new Error("Invalid S3 response");
      }

      return response.data;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };

  const handleChange = (field) => (event) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
    setError("");
  };

  const handleMobileChange = (field) => (event) => {
    let value = event.target.value.replace(/[^0-9]/g, "");
    if (value.length <= 10) {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
    setError("");
  };

  const handleWhatsAppChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      whatsApp: event.target.checked,
    }));
  };

  const isValidEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  // const handleSubmit = async () => {
  //   if (!formData.name || !formData.email || !formData.mobile) {
  //     setError("Please fill in all required fields");
  //     return;
  //   }

  //   if (!isValidEmail(formData.email)) {
  //     setError("Please enter a valid email address");
  //     return;
  //   }

  //   setIsLoading(true);
  //   try {
  //     let profilePicture = "photo";

  //     if (formData.photo) {
  //       try {
  //         console.log("Starting S3 upload...");
  //         profilePicture = await uploadToS3(formData.photo);
  //         console.log("S3 upload successful:", profilePicture);
  //       } catch (uploadError) {
  //         console.error("Image upload failed:", uploadError);
  //         setError("Failed to upload image. Continue with default photo?");
  //         return;
  //       }
  //     }

  //     const response = await registerUserApi({
  //       name: formData.name,
  //       email: formData.email,
  //       mobile: formData.mobile,
  //       whatsApp: formData.whatsApp,
  //       profilePicture,
  //       gender: formData.gender,
  //     });

  //     if (response?.status) {
  //       onClose();
  //     } else {
  //       setError(response?.message || "Registration failed. Please try again.");
  //     }
  //   } catch (err) {
  //     setError("Something went wrong. Please try again.");
  //     console.error("Registration Error:", err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const handleSubmit = async () => {
    if (!formData.name || !formData.email || !formData.mobile) {
      setError("Please fill in all required fields");
      return;
    }
  
    if (!isValidEmail(formData.email)) {
      setError("Please enter a valid email address");
      return;
    }
  
    setIsLoading(true);
    try {
      let profilePicture = "photo";
  
      if (formData.photo) {
        try {
          console.log("Starting S3 upload...");
          profilePicture = await uploadToS3(formData.photo);
          console.log("S3 upload successful:", profilePicture);
        } catch (uploadError) {
          console.error("Image upload failed:", uploadError);
          setError("Failed to upload image. Please try again.");
          setIsLoading(false);
          return;
        }
      }
  
      const userData = {
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        whatsApp: formData.whatsApp,
        profilePicture,
        gender: formData.gender,
      };
  
      // Dispatch register action
      const resultAction = await dispatch(registerUser(userData)).unwrap();
  
      if (resultAction?.status) {
        // After successful registration, fetch the updated profile
        await dispatch(fetchUserProfile()).unwrap();
        onClose(); // Close the registration dialog
      } else {
        setError(resultAction?.message || "Registration failed. Please try again.");
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
      console.error("Registration Error:", err);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className="flex items-center gap-2">
          <img
            src="./assets/images/logo.jpg"
            alt="Logo"
            style={{ marginRight: 8, width: "100px" }}
          />
          <Typography variant="h6">Welcome</Typography>
        </Box>
        <IconButton onClick={onClose}>
          <CloseIcon className="h-5 w-5" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className="flex flex-col items-center mt-4" my={2}>
          <Typography className="text-lg font-semibold text-gray-800">
            PERSONAL DETAILS
          </Typography>
          <Typography className="text-sm text-gray-600 " my={2}>
            Enter Your Personal Details To Continue
          </Typography>
        </Box>

        {/* Profile Picture Upload */}
        <Box display="flex" justifyContent="center" mb={4} position="relative">
          <input
            type="file"
            accept="image/*"
            id="profile-photo"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
          <label
            htmlFor="profile-photo"
            style={{ position: "relative", display: "inline-block" }}
          >
            <StyledAvatar src={formData.photoPreview}>
              {!formData.photoPreview && ""}
            </StyledAvatar>
            <StyledEditIcon>
              <EditIcon sx={{ fontSize: 16, color: "white" }} />
            </StyledEditIcon>
          </label>
        </Box>

        <Box className="flex flex-col gap-4 mt-6" my={2}>
          <Box my={2} className="flex flex-col">
            <TextField
              fullWidth
              placeholder="Enter Your Full Name"
              value={formData.name}
              onChange={handleChange("name")}
              className="rounded-md"
              required
            />
          </Box>

          <Box my={2} className="flex flex-col">
            <TextField
              fullWidth
              placeholder="Enter Your Email ID"
              value={formData.email}
              onChange={handleChange("email")}
              type="email"
              className="rounded-md"
              required
            />
          </Box>

          <Box my={2} className="flex flex-col">
            <TextField
              fullWidth
              placeholder="Enter Your Mobile Number"
              value={formData.mobile}
              onChange={handleMobileChange("mobile")}
              type="tel"
              className="rounded-md"
              required
              inputProps={{
                maxLength: 10,
                pattern: "[0-9]*",
                inputMode: "numeric",
              }}
            />
          </Box>

          <Box my={2} className="flex flex-col">
            <Select
              value={formData.gender}
              onChange={handleChange("gender")}
              className="rounded-md"
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </Box>

          <Box my={2} className="flex flex-col">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.whatsApp}
                  onChange={handleWhatsAppChange}
                />
              }
              label="Use this number for WhatsApp"
            />
          </Box>
        </Box>

        {error && (
          <Typography className="text-red-500 text-sm text-center mt-4">
            {error}
          </Typography>
        )}
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            onClick={handleSubmit}
            disabled={isLoading}
            variant="contained"
          >
            {isLoading ? "Registering..." : "Submit"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RegistrationDialog;